import { Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { Notification } from 'src/@types/notifications';
import { DeviceInfosApi } from 'src/api-client';

import { ActionButton } from './ActionButton';
import { useStrings } from 'src/hooks/useStrings';

export function PushNotification() {
  const { strings } = useStrings();
  const [notification, setNotification] = useState<Notification | null>();

  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      (notificationEvent) => {
        setNotification(notificationEvent.notification as Notification);
      }
    );

    OneSignal.User.PushSubscription.addEventListener(
      'change',
      (subscriptionChangeEvent) => {
        const currentSubscriptionId = subscriptionChangeEvent.current.id;

        if (currentSubscriptionId) {
          DeviceInfosApi.v1DeviceInfosPost({
            v1DeviceInfosPostRequest: {
              device_info: {
                provider: 'one-signal',
                fcm_token: currentSubscriptionId,
              },
            },
          });
        }
      }
    );
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(notification)}
      autoHideDuration={6000}
      onClose={() => setNotification(null)}
      message={notification?.body}
      title={notification?.title}
      action={
        notification?.actionButtons ? (
          <>
            {notification.actionButtons.map(({ text, launchURL, actionId }) => (
              <ActionButton text={text} launchUrl={launchURL} key={actionId} />
            ))}
          </>
        ) : notification?.launchURL ? (
          <ActionButton text={strings('show')} launchUrl={notification.launchURL} />
        ) : null
      }
    />
  );
}
