import { AlternateEmail } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import AppleSignin from 'react-apple-signin-auth';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Link as RouterLink } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import { PATH_AUTH } from 'src/routes/paths';

import { SignUpForm } from './SignUpForm';
import { useStrings } from 'src/hooks/useStrings';

const ContentStyle = styled('div')(({ theme }) => ({
  '#login-buttons': {
    flexGrow: 1,
  },
  '#login-buttons button': {
    display: 'flex !important',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px !important',
    overflow: 'hidden !important',
  },
  '#login-buttons button > div': {
    marginRight: '0 !important',
  },
}));

export const SignUpManager = () => {
  const { androidLogin, iosLogin } = useAuth();
  const { strings } = useStrings();

  const androidLoginSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    try {
      const postUsersAndroidApiArg = {
        body: {
          android: {
            accessToken: (response as any).accessToken,
          },
        },
      };

      await androidLogin(postUsersAndroidApiArg);
    } catch (error) {
      console.error(error);
    }
  };

  const loginFailure = async (error: string) => {
    console.log(error);
  };

  const iosLoginSuccess = async (response: any) => {
    try {
      const postUsersIosApiArg = {
        body: {
          ios: {
            identityToken: response.authorization.id_token,
            user: response.user,
            email: response.email,
            authorizationCode: response.authorization.code,
            fullName: {
              givenName: 'Usuario',
            },
          },
        },
      };

      await iosLogin(postUsersIosApiArg);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <CardContent>
        <ContentStyle>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={5}
              md={12}
              lg={4}
              xl={5}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Stack direction="column" spacing={2} id="login-buttons">
                <Typography
                  className="welcome-message"
                  sx={{ color: 'text.secondary' }}
                >
                  {strings('createAccountOrLogin')}
                </Typography>
                <GoogleLogin
                  clientId="468701863685-jv0cfukf09t6mmbfsfpu74km057gb5jt"
                  onSuccess={androidLoginSuccess}
                  onFailure={loginFailure}
                />

                <AppleSignin
                  authOptions={{
                    clientId: 'com.cabal-app.web',
                    scope: 'email name',
                    redirectURI: 'https://web.cabal-app.com',
                    usePopup: true,
                  }}
                  uiType="dark"
                  className="apple-auth-btn"
                  noDefaultStyle={false}
                  buttonExtraChildren="Continue with Apple"
                  onSuccess={iosLoginSuccess} // default = undefined
                  onError={loginFailure} // default = undefined
                  skipScript={false} // default = undefined
                  iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                />

                <Link component={RouterLink} to={PATH_AUTH.login}>
                  <Button
                    style={{
                      marginTop: 12,
                    }}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    startIcon={<AlternateEmail />}
                  >
                    {strings('login')}
                  </Button>
                </Link>
                <Link component={RouterLink} to={PATH_AUTH.passwordRestore}>
                  <Button fullWidth size="small">
                    {strings('passwordRecovery')}
                  </Button>
                </Link>
              </Stack>
            </Grid>
            <Grid xs={0} sm={2} md={0} lg={1}>
              <div
                style={{
                  width: 1,
                  background: '#eee',
                  height: '100%',
                  margin: '0 auto',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={12} lg={7} xl={6}>
              <Card>
                <CardContent>
                  <Typography
                    className="welcome-message"
                    sx={{ color: 'text.secondary', marginBottom: 3 }}
                  >
                    {strings('useEmail')}
                  </Typography>
                  <SignUpForm />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </ContentStyle>
      </CardContent>
    </Card>
  );
};
