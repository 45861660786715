import { GeneralStrings } from '../es/general';

export const general: GeneralStrings = {
  voiceWelcome:
    'Hi! Welcome to Cabal AI, click the button and say something like "Create a $10 expense"',
  voiceMicPermission: 'We need permission to use your microphone.',
  voiceNoMic: 'You need a microphone to use this feature.',
  voiceTimeLimit:
    'The audio you have recorded exceeds %maxSecs seconds, please try to be shorter.',
  voiceExpenseCreated: 'Expense created successfully',
  voiceProcessing: 'Processing...',
  accountUnableToLogout: 'Unable to logout!',
  accountProfile: 'Your profile',
  accountLogOut: 'Log out',
  whatsNewTitle: 'Latest updates',
  whatsNewNocontent: 'No content available',
  whatsNewError: 'Something went wrong',
  whatsNewPlublishedOn: 'Published on',
  close: 'Close',
  edit: 'Edit',
  loading: 'Loading',
  search: 'Search',
  sort: 'Sort',
  filter: 'Filter',
  by: 'by',
  name: 'Name',
  return: 'Go back',
  barcode: 'Barcode',
  none: 'None',
  priceUp: 'Price up',
  priceDown: 'Price down',
  new: 'New',
  new2: 'New',
  newer: 'Newer',
  older: 'Oldest',
  price: 'Price',
  cost: 'Cost',
  add: 'Add',
  buy: 'Buy',
  more: 'more',
  apply: 'Apply',
  next: 'Next',
  prev: 'Previous',
  youHave: 'You have',
  order: 'Order',
  employees: 'Employees',
  delete: 'Delete',
  cancel: 'Cancel',
  continue: 'Continue',
  writeConfirmation: 'To continue please write',
  selectAll: 'Select all',
  cancelSelection: 'Cancel selection',
  deleteSelection: 'Delete selection',
  bulkSelect: 'Bulk select',
  outOfStock: 'Out of stock',
  lowStock: 'Low stock',
  createNew: 'Create new',
  createNew2: 'Create new',
  requestReport: 'Request report',
  date1: 'Date',
  date2: 'Dates',
  from: 'From',
  until: 'Until',
  imageUploaderTitle: 'Select the images',
  imageUploaderDescription: 'Drag files or click here to choose images',
  imageUploaderRejection:
    'Please check the maximum number of files, and try again.',
  imageUploaderValidation: 'The maximum number of images is 5',
  required: 'Required',
  limit: 'Limit',
  image: 'image',
  images: 'images',
  max: 'max',
  currentImages: 'Existing images',
  imageDeleteConfirmation: 'Are you sure you want to delete this image?',
  deleteConfirmation: 'Please confirm to continue.',
  saveChanges: 'Save changes',
  save: 'Save',
  confirm: 'Confirm',
  important: 'Important',
  regenerate: 'Regenerate',
  regenerateWarning:
    'The services you have integrated will stop working. After regenerating the API Key, update all the integrations you have made with the new code to avoid problems.',
  apiKey: 'API Key',
  noApiKey: 'You do not have an active API Key.',
  copy: 'Copy',
  copied: 'Copied!',
  counterSale: 'Sales in cash',
  pointOfSaleInfo:
    'In the Point of Sale, you can make cash counter sales. If you want to make credit sales, go to the Accounting module.',
  goToAccounting: 'Go to Accounting',
  unexpectedError: 'An unexpected error occurred while loading this module.',
  inconvenienceMessage:
    'We apologize for the inconvenience, please try again later.',
  date: 'Date',
  orderCode: 'Order Code',
  total: 'Total',
  customer: 'Customer',
  phone: 'Phone',
  address: 'Address',
  items: 'Items',
  freeSale: 'Free Sale',
  discount: 'Discount',
  paymentMethod: 'Payment Method',
  cash: 'Cash',
  credit: 'Credit',
  thankYou: 'Thank you for your purchase!',
  appDescription: 'the app for entrepreneurs, available for Android and iOS.',
  notFound: 'Not found',
  checkTypos: 'Try checking for typos or using complete words.',
  enterKeywords: 'Please enter keywords',
  noResultsFoundFor: 'No results found for',
  quoteRegisteredSuccess: 'Quote successfully registered',
  quoteReadyMessage: 'The quote is ready, what would you like to do?',
  viewQuote: 'View quote',
  download: 'Download',
  saleRegisteredSuccess: 'Sale successfully registered',
  printOrShareReceipt: 'Would you like to print/share the receipt?',
  print: 'Print',
  permissionDenied: 'Permission denied',
  noAccess: 'You do not have permission to access this page.',
  emailNotification: 'You will receive an email',
  areYouSure: 'Are you sure?',
  aboutToDelete: 'You are about to delete',
  cannotUndo: 'You will not be able to undo this action.',
  confirmDelete: 'I understand and wish to delete it.',
  nameRequired: 'Name is required',
  validEmailRequired: 'Must be a valid email',
  phoneRequired: 'Phone is required',
  createContact: 'Create contact',
  email: 'Email',
  createContactAction: 'Create contact',
  or: 'or',
  chooseContact: 'Choose a contact',
  searchContact: 'Search contact',
  seeMore: 'See more',
  authorizationRequired: 'Authorization required',
  requestAccess: 'Please request access to',
  cabalPlus: 'Cabal Plus',
  organizationOwner: 'from the organization owner.',
  acquireCabalPlus: 'Acquire Cabal Plus',
  unexpectedErrorReportSent:
    'An unexpected error occurred, a report has been sent, and we will fix the issue as soon as possible.',
  selectPlaylist: 'Select playlist',
  playlists: 'Playlists',
  dragOrClickToSelectImage: 'Drag files or click here to select the image.',
  viewReceipt: 'View receipt',
  downloadReceipt: 'Download receipt',
  generating: 'Generating...',
  show: 'Show',
  filterByUser: 'Filter by user',
  all: 'All',
  productImage: 'Product Image',
  uploadImage: 'Upload Image',
  selectFromGallery: 'Select from gallery',
  selectDateRange: 'Select date range',
  input: 'input',
  startDate: 'Start date',
  endDate: 'End date',
  endDateGreater: 'The end date must be greater than the start date.',
  updateProductCost: 'Use this field to update the cost of this product.',
  viewExpense: 'View expense',
  startFreeTrial: 'Start free trial',
  welcomeToCabalPlus: 'Ready, Welcome to Cabal Plus',
  processingPayment:
    'Processing payment. We will notify you when we receive your payment.',
  paymentFailed: 'Payment failed. Please try another payment method.',
  subscribeFor: 'Subscribe for',
  buyNow: 'Buy now',
  trialEndsIn: 'Your trial period will end in',
  cabalPlusPrice3Months: 'Cabal Plus USD$2.99 x 3 Months',
  cabalPlusMonthlyPrice: 'USD$6.99 Month',
  employeeRolesPermissions: 'Roles and permissions for your employees',
  salesPlanForFinance: 'Sales plan for finance',
  offerCampaignsForStore: 'Offer campaigns for the online store',
  multipleBusinessesInOneAccount: 'Multiple businesses in one account',
  profitAndCashReports: 'Profit and cash reports',
  clickStartFreeTrial: 'Click the "Start Free Trial" button.',
  confirmationEmailReceived: 'Receive a confirmation email from',
  trialReminder:
    'When your 7 DAY trial is about to end, you will receive a reminder and can complete your payment within your account.',
  cancelAnytime: 'Cancel anytime you want, no explanations or complications.',
  subscriptionExpired: 'Your subscription has expired.',
  subscriptionCancelled:
    'Your subscription was cancelled because your payment failed.',
  trialExpired: 'Your trial period has expired.',
  subscriptionCancelledStatus: 'Your subscription is cancelled.',
  startFreeTrial7Days:
    'Start your 7 DAY free trial now, WITHOUT entering a credit/debit card.',
  choosePlanAfterTrial:
    'Then you can become Plus by choosing the plan that best suits you.',
  unlockYourPotential: 'Unlock your full potential with:',
  manyMoreFeatures: 'And many more features',
  easyToStart: 'Starting is very easy:',
  unlockYourPotentialNow: 'Ready, welcome to Cabal Plus!',
  selectPlan: 'Select your plan',
  changePlan: 'Change plan',
  errorOccurred: 'An error occurred',
  after3MonthsNormalPrice: 'After 3 months, normal price USD$6.99',
  pricePlans: '/year /month for 3 months /month',
  cancelAnytimePlan: 'Cancel anytime',
  getCabalPlus: 'Get Cabal Plus',
  day: 'day',
  days: 'days',
  pageNotFound: '404 Page Not Found',
  sorryPageNotFound: 'Sorry, page not found!',
  pageNotFoundMessage:
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  goToHome: 'Go to Home',
  forbidden403: '403 Forbidden',
  noPermission: 'No permission',
  restrictedAccessMessage:
    "The page you're trying to access has restricted access.",
  contactAdmin: 'Please refer to your system administrator.',
  joinCabalPlus: 'Join Cabal Plus',
  unableToLogout: 'Unable to logout!',
  createMyBusiness: 'I want to create my business',
  personalFinance: 'Personal Finance',
  joinBusinessAsGuest: 'I want to join a business as a guest',
  goBack: 'Go back',
  almostReady: 'We’re almost ready!',
  loginWithNewPassword: 'Please log in with your new password.',
  loginToContinueAccountCreation:
    'Please log in to continue with the account creation process.',
  emailConfirmationError: 'An error occurred while confirming your email.',
  login: 'Log In',
  resetPassword: 'Reset Password',
  recoverPassword: 'Recover Password',
  changePassword: 'Change Password',
  createAccount: 'Create Account',
  loginOrCreateAccountToJoin: 'Please log in or create an account to join',
  earnWithCabal: 'Earn with Cabal!',
  referOthers: 'Refer others to use Cabal, and if they become Plus,',
  clickToStartEarning: 'Click and start earning!',
  verifyEmail: 'Verify email',
  emailVerified: 'Email verified successfully',
  monthlyCommission: 'you get 40% commission every month!',
  webSales: 'Web Sales',
  incoming: 'Incoming',
  inProgress: 'In Progress',
  completed: 'Completed',
  webSaleRejected: 'Web sale rejected successfully',
  webSaleApproved: 'Web sale approved successfully',
  sale: 'Sale',
  webSale: 'Web Sale',
  dashboard: 'Dashboard',
  reject: 'Reject',
  coupon: 'Coupon',
  contact: 'Contact',
  creationDate: 'Creation Date',
  item: 'Item',
  quantity: 'Quantity',
  priceWithDiscount: 'Price with discount',
  tutorials: 'Tutorials',
  back: 'Back',
  id: 'ID',
  saleDate: 'Sale Date',
  product: 'Product',
  unitPrice: 'Unit Price',
  status: 'Status',
  actions: 'Actions',
  viewDetails: 'View Details',
  confirmDeletion: 'Confirm Deletion',
  confirmDeletionMessage: 'Are you sure you want to delete this sale?',
  accept: 'Accept',
  receiptGenerated: 'Receipt Generated',
  salesReceipt: 'Sales Receipt',
  paymentReminder: 'Payment Reminder',
  pending: 'Pending',
  loaded: 'Loaded',
  concept: 'Concept',
  quantityShort: 'Qty.',
  services: 'Services',
  products: 'Products',
  costOfSales: 'Cost of Sales',
  earnings: 'Earnings',
  registerCharge: 'Register charge',
  newSale: 'New Sale',
  accountingSalesInfo:
    'In Accounting, you can perform cash and credit sales. To generate orders / tickets for counter sales, go to the POINT OF SALES module.',
  goToPOS: 'Go to POS',
  charges: 'Charges',
  editIncome: 'Edit Income',
  deposits: 'Abonos',
  costOfIncome: 'Costo de Ingresos',
  registerDeposit: 'Registrar abono',
  newIncome: 'New Income',
  recurringExpense: 'Recurring Expense',
  newExpense: 'New Expense',
  income: 'Income',
  expenses: 'Expenses',
  newCategory: 'New Category',
  transactionCategory: 'Transaction Category',
  editTransactionCategory: 'Edit Transaction Category',
  transactionCategories: 'Transaction Categories',
  totalTransactions: 'Total Transactions:',
  viewTransactions: 'View Transactions',
  deposit: 'Deposit',
  transactionCategoryedit: 'Transaction Category | Edit',
  type: 'Type',
  description: 'Description',
  paymentType: 'Payment Type',
  amount: 'Amount',
  transactionCategoriesnew: 'Transaction Categories | New',
  newTransactionCategory: 'New Transaction Category',
  storeSettings: 'Store Settings',
  yourStore: 'Your Store',
  paymentSettings: 'Payment Settings',
  orders: 'Orders',
  inventory: 'Inventory',
  hello: 'Hello',
  whatDoYouWantToDoToday: 'What do you want to do today?',
  openStore: 'Open Store',
  shareMyStore: 'Share My Store',
  showQrCodeOfMyStore: 'Show QR Code of My Store',
  copyUrl: 'Copy URL',
  manageYourOnlineStore: 'Manage your online store',
  productsView: 'Products | View',
  productDetails: 'Product Details',
  productNotFound: '404 Product not found',
  productCategoriesAll: 'Product Category | All',
  wantToSearchSomething: 'Do you want to search for something?',
  productCategoryEdit: 'Product Category | Edit',
  assignProducts: 'Assign Products',
  productCategoryNew: 'Product Category | New',
  newProductCategory: 'New Product Category',
  productCategories: 'Product Categories',
  sales: 'Sales',
  costs: 'Costs',
  generalHome: 'General: Home',
  detailsAll: 'Details | All',
  cashClosing: 'Cash Closing',
  noteTransactionsBeforeSeptember:
    'NOTE: All transactions made before the last week of September were assigned to the owner of the organization',
  client: 'Client',
  outstandingDebts: 'Outstanding Debts',
  totalAmount: 'Total Amount',
  chargesPayments: 'Charges / Payments',
  debtsByUser: 'Debts by User',
  loadMore: 'Load More',
  debtsByTransaction: 'Debts by Transaction',
  searchByName: 'Search by Name',
  expense: 'Expense',
  code: 'Code',
  payment: 'Payment',
  searchByCode: 'Search by Code',
  registerPaymentToTransaction: 'Register Payment to Transaction',
  totalDebt: 'Total Debt',
  paid: 'Paid',
  user: 'User',
  charge: 'Charge',
  toTransaction: 'to Transaction',
  received: 'Received',
  register: 'Register',
  serviceCategoryAll: 'Service Category | All',
  serviceCategory: 'Service Category',
  serviceCategoryEdit: 'Service Category | Edit',
  editServiceCategory: 'Edit Service Category',
  serviceCategoryNew: 'Service Category | New',
  newServiceCategory: 'New Service Category',
  inventoryCreateNewService: 'Inventory: Create a New Service',
  createNewService: 'Create a New Service',
  newService: 'New Service',
  serviceEdit: 'Service | Edit',
  editService: 'Edit Service',
  servicesAll: 'Services | All',
  rolesPermissions: 'Roles and Permissions',
  quotes: 'Quotes',
  createQuote: 'Create Quote',
  quote: 'Quote',
  expirationDate: 'Expiration Date',
  validity: 'Validity',
  inventoryAnalytics: 'Inventory Analytics',
  updateSale: 'Update Sale',
  doYouWantToPrintReceipt: 'Do you want to print the receipt?',
  no: 'No',
  yes: 'Yes',
  ordersCommands: 'Orders / Commands',
  viewWebOrdersHere:
    'Now you can view your web orders on this screen, set it up in Customization.',
  goToCustomization: 'Go to Customization',
  importData: 'Import Data',
  noSellRentData:
    'We do not sell, rent, or use your database for commercial purposes.',
  uploadHistory: 'Upload History',
  organizations: 'Organizations',
  successfullyJoined: 'You have successfully joined',
  invitationCode: 'Invitation Code',
  logout: 'Logout',
  yesJoin: 'Yes, Join',
  exit: 'Exit',
  notPartOfBusiness:
    'You are not part of a business at the moment. If you have an invitation code, use it in the form below.',
  findInvitationCode: 'You can find your invitation code in your email.',
  customizeOrganization: 'Customize Organization',
  joinTo: 'Join to',
  hellov2: 'Hello',
  joinOrganization: 'Do you want to join the organization?',
  withEmail: 'with the email',
  join: 'Join',
  extrasNew: 'Extras | New',
  extrasEdit: 'Extras | Edit',
  extrasAll: 'Extras | All',
  viewMore: 'View More',
  financialAssistant: 'Financial Assistant',
  financialStatements: 'Financial Statements',
  financialStatementsDescription:
    'Remember that financial statements summarize your performance and the resources you own, owe, and accumulate.',
  indicatorsDescription:
    'Indicators are the best way to measure your performance, these ratios are derived from financial statements and help assess the health of your business.',
  wantToLearnMore: 'Want to learn more?',
  channelHelpMessage:
    'Remember that our channel provides the best content to manage your business.',
  financialIndicators: 'Financial Indicators',
  contacts: 'Contacts',
  newContact: 'New contact',
  sortBy: 'Sort by',
  transactionCount: 'Transaction count',
  transactions: 'Transactions',
  debt: 'Debt',
  contactEdit: 'Contact | Edit',
  productsByUser: 'Products by user',
  transactionsByUser: 'Transactions by user',
  chargesCredits: 'Charges / Credits',
  registerPayment: 'Register payment to transaction',
  verifyAccount: 'Verify Account',
  salesInCash: 'Sales in cash',
  salesCredit: 'Sales credit',
  salesOnline: 'Sales online',
  shopifySales: 'Shopify sales',
  shopifyCosts: 'Shopify costs',
  discounts: 'Total discounts',
  costsTotal: 'Total costs',
  expensesInCash: 'Expenses in cash',
  expensesCredit: 'Expenses credit',
  lowStockProducts: 'Low stock products',
  zeroStockProducts: 'Zero stock products',
  skippedCosts: 'Skipped costs',
  depositsTotal: 'Total deposits',
  availableCash: 'Savings',
  incomeReceived: 'Income received',
  pendingIncome: 'Pending income',
  paidExpenses: 'Paid expenses',
  pendingExpenses: 'Pending expenses',
  myFinances: 'My finances',
  weekly: 'Weekly',
  creditSales: 'Credit sales',
  cashSales: 'Cash sales',
  accountsReceivablePayable: 'Accounts receivable and payable',
  incomeStatement: 'Income statement',
  webOrdersSummary: 'Web orders summary',
  inventoryDetail: 'Inventory detail',
  monthly: 'Monthly',
  sortByWeek: 'Sort by week',
  sortByMonth: 'Sort by month',
  viewAll: 'View all',
  viewTransaction: 'View transaction',
  onlineSales: 'Online sales',
  totalSales: 'Total sales',
  cashCost: 'Cash cost',
  creditCost: 'Credit cost',
  onlineCost: 'Online cost',
  cashExpenses: 'Cash expenses',
  creditExpenses: 'Credit expenses',
  accountingLoss: 'Accounting loss',
  accountingProfit: 'Accounting profit',
  finished: 'Finished',
  creditSalesPayments: 'Credit sales payments',
  productPurchases: 'Product purchases',
  creditExpensesPayments: 'Credit expenses payments',
  productsServices: 'Products/Services',
  categories: 'Categories',
  totalInventoryCost: 'Total inventory cost',
  totalInventoryValue: 'Total inventory value',
  week: 'week',
  month: 'month',
  orderBy: 'Order by',
  coupons: 'Coupons',
  yourOffers: 'Your offers',
  editDiscount: 'Edit discount',
  duplicateDiscount: 'Duplicate Discount',
  editCoupon: 'Edit Coupon',
  duplicateCoupon: 'Duplicate Coupon',
  createAccountOrLogin: 'Create your account or log in using Google or Apple.',
  passwordRecovery: 'Recover password',
  useEmail: 'or use your email:',
  emailRequired: 'Your email address is required',
  passwordRequired: 'Please enter a password',
  passwordsMustMatch: 'Passwords must match',
  password: 'Password',
  confirmPassword: 'Confirm password',
  emailSent:
    'We have sent you an email to confirm your account. Please follow the instructions to continue.',
  passwordUpdated: 'Password updated',
  newPassword: 'New password',
  validEmail: 'You must enter a valid email address',
  send: 'Send',
  currentPassword: 'Current password',
  firstName: 'First name',
  lastName: 'Last name',
  invitationCodeTooltip: 'You can find your invitation code in your email.',
  loggedInAs: 'You are logged in as',
  finishRegistration: 'Finish registration',
  firstNameRequired: 'Your first name is required',
  lastNameRequired: 'Your last name is required',
  currentPasswordRequired: 'Please enter your current password',
  newPasswordRequired: 'Please enter your new password',
  changesSaved: 'Changes saved!',
  searchCategory: 'Search category',
  categoryRequired: 'Category is required',
  categoryModified: 'Category modified successfully',
  categoryCreated: 'Category created successfully',
  notEditable: 'This category is not editable.',
  categoryType: 'Category type',
  categoryName: 'Category name',
  categoryDescription: 'Category description',
  createCategory: 'Create category',
  categoryDeleted: 'Category deleted successfully',
  noDescription: 'No description',
  enterNumber: 'Enter a number',
  greaterThanZero: 'Income must be greater than or equal to zero',
  maxCharacters: 'The maximum number of characters is 35',
  requiredValue: 'This value is required',
  validDate: 'You must enter a valid date',
  createIncome: 'Create Income',
  card: 'Card',
  transfer: 'Transfer',
  onCredit: 'On credit',
  incomeCreated: 'Income created successfully',
  chargev2: 'Charge',
  createExpense: 'Create Expense',
  realized: 'Realized',
  productNotRegistered: 'You have not registered this product.',
  lastScan: 'Last Scan',
  firstOfMonth: 'First day of the month',
  lastOfMonth: 'End of the month',
  middleOfMonth: 'Middle of the month',
  each: 'Each',
  ofTheMonth: 'of the month',
  chooseProduct: 'Choose the product you want to',
  sell: 'sell',
  searchSomething: 'Do you want to search for something?',
  productOutOfStock: 'Product out of stock',
  notEnoughStock: 'You do not have enough stock for this sale.',
  ok: 'Ok',
  inThisSale: 'In this sale:',
  choosetheserviceyouwant: 'Choose the service you want to',
  validNumber: 'Enter a valid number',
  greaterOrEqualOne: 'Must be greater or equal to 1',
  requiredField: 'Required field',
  dateRequired: 'Date is required',
  expenseCreated: 'Expense created successfully',
  inventoryDisabled: 'Inventory disabled',
  inThisExpense: 'In this expense',
  purchaseProduct: 'Purchase product',
  cashPayment: 'Cash',
  cardPayment: 'Card',
  bankTransfer: 'Bank transfer',
  payable: 'Payable',
  recurrentExpense: 'Recurrent expense',
  months: 'Months',
  period: 'Period',
  select: 'Select',
  category: 'Category',
  for: 'for',
  recurringAmountNotice: 'This amount will be recorded every',
  paymentReminderNotice: 'We will send you payment reminders every',
  noExpenseRegistered: 'This expense will not be recorded.',
  saleEditedSuccessfully: 'Sale edited successfully',
  enterValidNumber: 'Enter a valid number',
  additionalSale: 'Additional sale',
  comments: 'Comments',
  editSale: 'Edit sale',
  optional: 'Optional',
  recurringSale: 'This sale is recurring.',
  editQuote: 'Edit Quote',
  thisSaleIsRecurrent: 'This sale is recurrent.',
  quoteEditedSuccessfully: 'Quote edited successfully',
  maxCharacters140: 'The maximum number of characters is 140',
  requiredDate: 'Date is required',
  enterValidDate: 'Enter a valid date',
  inThisQuote: 'In this quote',
  recurrentSale: 'Recurrent Sale',
  saleCreatedSuccessfully: 'Sale created successfully',
  quoteCreatedSuccessfully: 'Quote created successfully',
  daysOfValidity: 'Days of validity',
  toReceive: 'To receive',
  thissalewillnotberegistered: 'This sale will not be recorded.',
  createSale: 'Create Sale',
  expand: 'Expand',
  collapse: 'Collapse',
  stock: 'Stock',
  searchServices: 'Search services',
  highestPrice: 'Highest price',
  lowestPrice: 'Lowest price',
  aToZ: 'A - Z',
  zToA: 'Z - A',
  mostRecent: 'Most Recent',
  oldest: 'Oldest',
  allServices: 'All services',
  discountApplied:
    'The discount will be applied to ALL services in your inventory, including those not visible in the list.',
  serviceDeleted: 'Service successfully deleted',
  priceRequired: 'Price is required',
  maxImages: 'The maximum number of images is 5',
  serviceName: 'Service Name',
  serviceDescription: 'Service Description',
  noCategory: 'No Category',
  createNewCategory: 'Create New Category',
  showInStore: 'Show in Online Store',
  showInPOS: 'Show in POS',
  configureAddons: 'Configure Add-ons',
  salePrice: 'Sale Price',
  createService: 'Create Service',
  fixErrors: 'Please fix the errors and try again.',
  serviceUpdated: 'Service updated successfully',
  serviceCreated: 'Service created successfully',
  addCategory: 'Add category',
  categoryCreatedSuccessfully: 'Category created successfully',
  categoryDeletedSuccessfully: 'Category deleted successfully',
  users: 'Users',
  invitations: 'Invitations',
  usersCount: 'Users',
  ariaLabelUsers: 'users',
  ariaLabelInvitations: 'invitations',
  usersAndInvitations: 'users and invitations',
  invitationAccepted: 'Invitation accepted',
  invitationPending: 'Invitation pending',
  noInvitesYet: "You haven't invited anyone to the role",
  inviteUsers: 'Invite users',
  irreversibleAction: 'Irreversible action',
  deleteInvitation: 'Do you want to delete the invitation',
  invitationNumber: 'Invitation #',
  invitationsSentSuccessfully: 'Invitations sent successfully.',
  addAnother: 'Add another',
  sending: 'Sending...',
  sendInvitations: 'Send invitations',
  roleNameRequired: 'Role name is required',
  roleUpdatedSuccessfully: 'Role updated successfully',
  updatingRole: 'Updating role',
  creatingRole: 'Creating role',
  updateRole: 'Update role',
  createRole: 'Create role',
  pointOfSale: 'Point of Sale',
  serviceCategories: 'Service Categories',
  details: 'Details',
  store: 'Store',
  allowAll: 'Allow all',
  serviceCatalog: 'Service Catalog',
  createServices: 'Create Services',
  deleteServices: 'Delete Services',
  editServices: 'Edit Services',
  seeServiceCategories: 'See Service Categories',
  createServiceCategories: 'Create Service Categories',
  updateServiceCategories: 'Update Service Categories',
  deleteServiceCategories: 'Delete Service Categories',
  editOrders: 'Edit Orders',
  deleteOrders: 'Delete Orders',
  seeAnalytics: 'See Analytics',
  productCatalog: 'Product Catalog',
  seeProductCosts: 'See Product Costs',
  createProducts: 'Create Products',
  deleteProducts: 'Delete Products',
  bulkDeleteProducts: 'Bulk Delete Products',
  editProducts: 'Edit Products',
  seeStockAnalytics: 'See Stock Analytics',
  seeModifiers: 'See Modifiers',
  seeFinancialStatements: 'See Financial Statements',
  seeCategories: 'See Categories',
  createCategories: 'Create Categories',
  updateCategories: 'Update Categories',
  bulkAssignCategories: 'Bulk Assign Categories',
  deleteCategories: 'Delete Categories',
  budgetManagement: 'Budget Management',
  seeDetailsAnalytics: 'See Details Analytics',
  deleteTransactions: 'Delete Transactions',
  massiveImport: 'Massive Import',
  manageOffers: 'Manage Offers',
  configureStore: 'Configure Store',
  deleteRoleMessage: 'Do you want to delete the role?',
  deleteRole: 'Delete role',
  saleGreaterThanZero: 'The sale must be greater than or equal to zero',
  maxCharacters35: 'The maximum number of characters is 35',
  depositRegisteredSuccessfully: 'Deposit registered successfully',
  bank: 'Bank transfer',
  quoteExpired: 'Quote expired',
  generateSale: 'Generate sale',
  incomes: 'Incomes',
  savings: 'Savings',
  topExpenseCategories: 'Top expense categories',
  quoteDeletedSuccessfully: 'Quote deleted successfully',
  deleteQuoteConfirmation: 'Delete Quote',
  pleaseConfirmToProceed: 'Please confirm to proceed.',
  searchQuoteById: 'Search quote by ID',
  profit: 'Profit',
  confirmationRequired: 'Confirmation required',
  deleteTransactionMessage:
    'The transaction you are about to delete will affect business expenses and product inventory in this operation.',
  allFilter: 'All',
  cashFilter: 'Cash',
  cardFilter: 'Card',
  transferFilter: 'Transfer',
  creditFilter: 'Credit',
  creditPayment: 'Credit',
  saleLabel: 'Sale',
  incomeLabel: 'Income',
  posLabel: 'POS',
  purchaseLabel: 'Purchase',
  expenseLabel: 'Expense',
  depositLabel: 'Deposit',
  deleteTransactionTitle: 'Delete transaction',
  deleteConfirmMessage: 'Please confirm to continue',
  cancelButton: 'Cancel',
  deleteButton: 'Delete',
  searchCodeLabel: 'Search by code',
  searchCodePlaceholder: 'Ex. UAZCXWSZ',
  paymentMethodsLabel: 'Payment methods',
  typeColumn: 'Type',
  idColumn: 'ID',
  descriptionColumn: 'Description',
  paymentTypeColumn: 'Payment type',
  contactColumn: 'Contact',
  amountColumn: 'Amount',
  dateColumn: 'Date',
  actionsColumn: 'Actions',
  showMoreButton: 'Show more',
  filtersApplied: 'Filters applied',
  transactionDeleted: 'Transaction deleted',
  recurringTransactionTooltip: 'Recurring transaction',
  cashRegisterCut: 'Cash Register Cut',
  selectTimeRange: 'Please select a time range to generate the report:',
  daily: 'Daily',
  custom: 'Custom',
  error: 'An error occurred, please try again later.',
  downloadCashRegisterCut: 'Download',
  loadingCashRegisterCut: 'Loading',
  cashRegisterCutSuccess: 'Cut generated successfully',
  cashRegisterCutError: 'An error occurred',
  cashRegisterCutFileName: 'cash_register_cut',
  cashType: 'Cash',
  creditType: 'Credit',
  dateCut: 'Cut',
  cashSalesCashCut: 'Cash Sales',
  transferPayment: 'Transfers',
  cardPaymentCashCut: 'Cards',
  webOrders: 'Web Orders',
  totalLabel: 'Total',
  creditSalesCashCut: 'Credit Sales',
  totalSalesCashCut: 'Total Sales',
  cashCharges: 'Cash Charges',
  transferCharges: 'Transfer Charges',
  cardCharges: 'Card Charges',
  totalCashGenerated: 'Total Cash Generated',
  pendingToLiquidate: 'Pending to Liquidate',
  cashExpensesCashCut: 'Cash Expenses/Purchases',
  creditExpensesCashCut: 'Credit Expenses/Purchases',
  totalExpenses: 'Total Expenses/Purchases',
  cashDeposits: 'Cash Deposits',
  transferDeposits: 'Transfer Deposits',
  cardDeposits: 'Card Deposits',
  totalCashOutcome: 'Total Cash Outcome',
  cashRegisterBalance: 'CASH REGISTER BALANCE',
  productHistory: 'Product purchase history',
  transactionHistory: 'Transaction history',
  editContact: 'Edit contact',
  deleteContact: 'Delete contact',
  contactDeleted: 'Contact deleted successfully',
  productsLabel: 'Products',
  transactionsLabel: 'Transactions',
  nameLabel: 'Name',
  phoneLabel: 'Phone',
  emailLabel: 'Email',
  addressLabel: 'Address',
  contactModified: 'Contact modified successfully',
  contactCreated: 'Contact created successfully',
  successfully: 'successfully',
  salesToReceive: 'Sales to receive',
  expensesToPay: 'Expenses to pay',
  productDeletedSuccessfully: 'Product deleted successfully',
  inStock: 'IN STOCK',
  unifyWebAndPOS: 'Unify Web and POS sales',
  unifyWebAndPOSDescription:
    'This option will allow you to view your web and Point of Sale orders in one place (the POS Orders section)',
  unifyTransactions: 'Unify transactions',
  salesReceipts: 'Sales receipts',
  addLogoToReceipts:
    '1. Add your business logo to your sales receipts (tickets).',
  hideLogoOnReceipts: 'Hide logo on receipts',
  freeTextFields:
    '2. Free text fields. Use these fields to add extra information to your receipts.',
  line1: 'Line 1',
  line2: 'Line 2',
  line3: 'Line 3',
  line4: 'Line 4',
  addCustomMessageToReceipts:
    '3. Add a custom message to your sales receipts (tickets).',
  receiptMessage: 'Receipt message',
  imageFormats: '*.jpeg, *.jpg, *.png, *.gif\n250x250 max.',
  onlineStore: 'Online Store',
  addBusinessColors: 'Add your business colors and customize your store URL.',
  customizeURL: 'Customize your URL',
  primaryColor: 'Primary Color',
  buttonExample: 'Button Example',
  invalidColor: 'Invalid Color',
  secondaryColor: 'Secondary Color',
  lowStockWarning: 'Low stock warning',
  lowStockWarningDescription:
    'Use this field to set the minimum stock quantity in your inventory. If your product stock falls below this amount, we will show you a low stock warning.',
  personalizationUpdatedSuccessfully: 'Personalization updated successfully',
  newModifier: 'New Modifier',
  color: 'Color',
  service: 'Service',
  create: 'Create',
  deletedSuccessfully: 'Deleted successfully',
  editModifier: 'Edit',
  deleteModifier: 'Delete',
  whatsTheDifference: "What's the difference?",
  couponsDescription:
    'You can create alphanumeric codes for your customers to enter at the time of purchase and they will receive a % percentage or $ money reduction on their Total amount.',
  note: 'Note',
  noteDescription: 'It will apply to all products in your catalog.',
  discountsDescription:
    'You can apply discounts in % percentages or $ money to one or more products in your catalog',
  couponActivatedSuccessfully: 'COUPON activated successfully',
  couponDeactivatedSuccessfully: 'COUPON deactivated successfully',
  discountActivatedSuccessfully: 'DISCOUNT activated successfully',
  discountDeactivatedSuccessfully: 'DISCOUNT deactivated successfully',
  confirmAction: 'Confirm action',
  activateCoupon: 'activate the coupon',
  deactivateCoupon: 'deactivate the coupon',
  activateDiscount: 'activate the discount',
  deactivateDiscount: 'deactivate the discount',
  yesActivate: 'Yes, activate',
  yesDeactivate: 'Yes, deactivate',
  activate: 'Activate',
  deactivate: 'Deactivate',
  whish: 'Would you like to',
  the: 'the',
  percentage: 'Percentage',
  money: 'Money',
  applyValueMessage: 'This value will be applied to',
  selectedProducts: 'to the products you choose from your catalog',
  activeCampaigns: 'Active campaigns',
  startDateTime: 'Start date and time',
  endDateTime: 'End date and time',
  value: 'Value',
  unit: 'Unit',
  createdAt: 'Created at',
  expiresAt: 'Expires at',
  active: 'Active',
  inactive: 'Inactive',
  maxActivations: 'Max. Activations',
  percentageWithSymbol: 'Percentage (%)',
  before: 'Before',
  now: 'Now',
  discountCreated: 'Discount created successfully',
  couponValueRequired: 'You must specify the coupon value',
  startDateRequired: 'You must select a start date',
  endDateRequired: 'You must select an expiration date',
  selectProducts: "You must select at least 1 product or check 'All products'.",
  discountLabel: 'How do you want your discount to work?',
  discountDurationLabel: 'Define how long you want the discount to be active',
  productsInDiscount: 'Products in this discount',
  chooseProducts: 'Choose the products you want to activate the discount for',
  createDiscount: 'Create discount',
  applyDiscount: 'It will apply',
  toOriginalPrices: 'to the original prices of the selected products',
  validFrom: 'It will be valid from',
  to: 'to',
  allProducts: 'all inventory products',
  immediateApplication:
    'When created, it will be applied immediately and your customers will see them.',
  onlineStoreMessage: 'A message bar will be published in your online store.',
  willBeApplied: 'Will be applied',
  expiration: '(expiration)',
  discountWillApply: 'The discount will apply to',
  thev2: 'the',
  selected: 'selected',
  publishDiscounts: 'Yes, I want to publish the discounts',
  barColor: 'Bar color',
  writeText: 'Write the text',
  preview: 'Preview',
  couponCreated: 'Coupon created successfully',
  couponNameRequired: 'You must specify the coupon name',
  couponCodeRequired: 'You must specify the coupon code',
  couponCodeMaxLength: 'Must be 15 characters maximum',
  maxActivationsDescription:
    'Limits the number of times this coupon can be redeemed.',
  shareCodeWarning: 'You must share the code.',
  shareCodeDescription:
    'The code will not be published anywhere in your online store, you can share it on social media, or as you prefer.',
  createNewCoupon: 'Create new coupon',
  createCoupon: 'Create coupon',
  toTotalAmount: 'to the total amount when placing an order.',
  chooseActiveCode:
    'You can choose in Active Campaigns which code you want to have active',
  onlyOneCode: 'You can only activate one code at a time',
  exampleCode: 'Example: PROM0001',
  instruction:
    'Your customers will need to enter this code when placing their order on your online store',
  discountFunctionality: 'How would you like your discount to work?',
  totalPurchase: 'on the total purchase',
  defineDuration: 'Define how long you want the discount to be active',
  limitUsage: 'Limit the number of times this coupon can be redeemed.',
  createCode: 'Create your code',
  validUntil: 'to the total amount at the time of placing an order.',
  searchOrders: 'Search orders',
  posOrders: 'POS',
  orderRejected: 'Web order rejected successfully',
  confirmReject: 'Are you sure you want to reject it?',
  notPaid: 'Not Paid',
  delivered: 'Delivered',
  complete: 'Complete',
  orderDeleted: 'Order deleted successfully',
  editOrder: 'Edit order',
  webOrder: 'Web order',
  pos: 'POS',
  homeDelivery: 'Home delivery',
  table: 'Table',
  comment: 'Comment',
  detail: 'Detail',
  delivery: 'Delivery',
  createOrder: 'Create order',
  saleUpdated: 'Sale updated successfully',
  saleCreated: 'Sale created successfully',
  confirmCancelOrder: 'Confirm cancel order',
  allValuesWillBeDeleted: 'All values will be deleted.',
  cancelOrder: 'Cancel order',
  goToPayment: 'Go to payment',
  updateOrder: 'Update order',
  generatePayment: 'Generate payment',
  deliveryAmount: 'Delivery cost',
  clientName: 'Client name',
  otherNonOperatingIncome: 'Other non-operating income',
  customize: 'Customize',
  totalPrice: 'Total price',
  selectPaymentMethod: 'Select payment method',
  itemInOrder: 'item in the order',
  subtotal: 'Subtotal',
  totalToPay: 'Total to pay',
  itemsInOrder: 'items in the order',
  saleWithoutProductsOrServices:
    'This sale does not include products or services.',
  enterAmount: 'Enter amount',
  changeToClient: 'Change to client',
  grossSales: 'Gross sales',
  netSales: 'Net sales',
  totalOrders: 'Total orders',
  avgTicket: 'Average tickets',
  cashSalesv2: 'Cash sales',
  cardSales: 'Card sales',
  bankSales: 'Bank sales',
  topSellingProducts: 'Top selling products',
  topSellingServices: 'Top selling services',
  removeProperty: 'Remove property',
  addProperty: 'Add property',
  size: 'Size',
  style: 'Style / Material',
  imageLimit: 'Limit of 5 images per product',
  barcodeInUse: 'Barcode is already in use',
  searchProducts: 'Search products',
  discountWarning:
    'The discount will apply to ALL products in your inventory, including those not visible in the list.',
  searchBy: 'Search by',
  costPercentage: '% Cost',
  grossMargin: 'Gross margin',
  unsoldProducts: 'Unsold products',
  bestSellers: 'Best sellers',
  worstSellers: 'Worst sellers',
  inCollection: 'In collection',
  viewProducts: 'View products',
  averageMonthlySales: 'Average monthly sales (last year)',
  averageMonthlyProductSales: 'Average monthly product sales (last year)',
  averageMonthlyProductCost: 'Average monthly product cost (last year)',
  averageMonthlyExpenses: 'Average monthly expenses (last year)',
  averageMonthlyProfits: 'Average monthly profits (last year)',
  finalSalesPlan: 'Final sales plan',
  planCosts: 'Final plan costs',
  operativeExpenses: 'Operative expenses',
  finalUtilityRevenue: 'Utility / Profit',
  lastYearSales: 'Last year sales',
  salesProyectionType: 'Projection method',
  incremental: 'Incremental',
  zero_base: 'Zero base',
  infoZeroBase: 'Your plan will NOT take historical information into account',
  infoIncremental: 'Your plan will be based on historical information',
  salesGrowthType: 'Growth type',
  growth: 'Growth',
  salesGrowthAmount: 'Enter amount',
  salesPlan: 'Sales plan',
  finalPlan: 'Final plan',
  adjustmentsPlan: 'Adjustments plan',
  summary: 'Summary',
  lastYearExpenses: 'Last year expenses',
  growthProyectionType: 'Projection method',
  expensesGrowthType: 'Growth type',
  expensesGrowthAmount: 'Enter amount',
  expensesPlan: 'Expenses plan',
  finalExpenses: 'Final expenses',
  profitAverage: 'On average, your products yield a profit',
  profitSummary: 'On average, your products yield',
  grossProfit: 'Gross profit',
  finalPlanCosts: 'Final plan costs',
  salesCost: 'Cost of sales',
  bruteMargin: 'Gross margin',
  ebitda: 'Operating margin (EBITDA)',
  otherIncomes: 'Other revenues',
  otherExpenses: 'Other expenses',
  netMargin: 'Net margin',
  mainIndicators: 'Main Income Statement Indicators',
  percentageWeight: 'Weight % of revenues',
  totalSalesEFFF: '(+) Total sales (credit & cash)',
  salesCostEFFF: '(-) Sales cost',
  discountsEFFF: '(-) Total discounts',
  bruteMarginEFFF: '(=) Gross profit',
  operativeExpensesEFFF: '(-) Operative expenses',
  ebitdaEFFF: '(=) Operational profit (EBITDA)',
  otherIncomesEFFF: '(+) Other incomes',
  otherExpensesEFFF: '(-) Other expenses',
  accUtilityEFFF: '(+/-) Accounting profit',
  incomeStatementEFFF: 'Income Statement',
  accUtility: '(+/-) Accounting profit',
  atc: '(+/-) Net change in accounts receivable',
  atp: '(+/-) Net change in accounts payable',
  inventoryEFFF: '(+/-) Inventory',
  operativeFlow: '(+/-) Operating cash flow',
  furnitureAndEquipment: '(+/-) Purchase of furniture and equipment',
  investmentFlow: '(+/-) Investment cash flow',
  ownResources: '(+/-) Own resources',
  financingFlow: '(+/-) Financing cash flow',
  initialBox: 'Cash at the beginning of the period',
  netPeriodFlow: '(+/-) Net cash flow',
  cashFlow: 'Cash flow',
  daysAccountsReceivable: 'Days accounts receivable (AR)',
  inventoryDays: 'Inventory days',
  daysAccountsPayable: 'Days accounts payable (AP)',
  cashCycle: 'Cash cycle (AR+Inv-AP)',
  returnOnEquity: 'Return on Equity (ROE)',
  returnOnAssets: 'Return on Assets (ROA)',
  mainBalanceSheetIndicators: 'Main balance sheet indicators',
  workingCapitalManagement: 'Working capital management',
  turnovers: 'Turnovers',
  profitability: 'Profitability',
  accountsReceivable: 'Accounts receivable',
  inventories: 'Inventories',
  totalAssets: 'Total assets',
  accountsPayable: 'Accounts payable',
  totalLiabilities: 'Total liabilities',
  ownResourcesEquity: 'Own resources (equity)',
  currentEarnings: 'Current earnings',
  accumulatedAccountingProfit: 'Accumulated accounting profit',
  totalEquity: 'Total equity',
  balanceSheet: 'Balance Sheet',
  assets: 'Assets',
  liabilitiesAndEquity: 'Liabilities + equity',
  cashReflectedInOwnResources:
    'This cash will also be reflected in the Own Resources account in Equity',
  newEntry: 'New entry',
  enterBalanceAmounts: 'Enter balance amounts',
  outOfStockProductsAtEnd:
    'Your out-of-stock products will be displayed at the end of the listing.',
  paymentProcessorNotice:
    'Cabal is not a payment processor. The link you must provide must be from the provider you have contracted to make digital charges to your customers',
  pleaseWriteDescription: 'Please write a description of your business',
  requiredFieldsMissing:
    'There are required fields incomplete, please review the form and try again:',
  howWillYouDispatchOrders: '1. How will you dispatch your orders?',
  whatPaymentOptionsWillYouGiveToYourCustomers:
    '2. What payment options will you give to your customers?',
  letYourCustomersKnowYourSchedule: '3. Let your customers know your schedule',
  whatDescriptionDoYouWantYourCustomersToSee:
    '4. What description do you want your customers to see?',
  doYouWantToAddYourBusinessSocialMedia:
    '5. Do you want to add your business social media?',
  paymentLink: '6. Payment link',
  showOutOfStockProductsAtTheEnd: '7. Show out-of-stock products at the end',
  yourStoreBanner: '8. Your store banner',
  currentBanner: 'Current banner:',
  costOfShipping: 'Shipping cost',
  rememberShippingCostInfo:
    'Remember that you are responsible for the shipping cost. Your customers will be informed that the cost may vary depending on the area.',
  pickupInStore: 'Pick up in store',
  deliverToHome: 'Deliver to home',
  uponDelivery: 'Upon delivery',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'Twitter',
  whatsapp: 'WhatsApp',
  yourUsername: 'your-username',
  yourFacebookUrl: 'https://facebook.com/your-url',
  enterYourWhatsappNumberInfo:
    'Please enter your city code (if applicable) and your WhatsApp number without dashes or spaces.',
  shippingCostReminder:
    'Remember, you are responsible for the shipping cost. Customers will be informed that the cost may vary by location.',
  usernamePlaceholder: 'your-username',
  cityCodeLabel: 'city code',
  enterYourLabel: 'Please enter your',
  optionalWhatsAppLabel: '(if applicable) and your WhatsApp number',
  noDashesNoSpaces: 'no dashes and no spaces',
  addressRequired: 'Your address is required',
  bankNameRequired: 'Your bank is required',
  bankNumberRequired: 'Your account number is required',
  confirmChanges: 'Confirm changes',
  reviewMessage:
    'Your information will be reviewed. Meanwhile, your online store will not be able to process card payments. Do you wish to continue?',
  noCancel: 'No, cancel',
  yesConfirm: 'Yes, confirm',
  requestAccepted: 'Your request has been accepted.',
  requestRejected: 'Your request has been rejected.',
  requestInProgress: 'Your information is under review.',
  adminMessage: 'Your information is incorrect.',
  bankName: 'Name of the bank where you will receive your sales:',
  bankSelection: 'Select your bank',
  accountReminder:
    'Remember that the account name must match the name associated with your Cabal account.',
  accountNumber: 'Account number:',
  uploadFrontId: 'Upload a photo of the front of your ID.',
  uploadBackId: 'Upload a photo of the back of your ID.',
  uploadFacePhoto: 'Upload a photo of your face',
  current: 'Current:',
  editOrganization: 'Edit organization',
  createOrganization: 'Create organization',
  businessNameRequired: 'Your business name is required',
  businessTypeRequired: 'Business type is required',
  industryRequired: 'Please select your industry',
  countryRequired: 'Country is required',
  businessName: 'Business name',
  country: 'Country',
  selectCountry: 'Select your country',
  currencyFormat: 'Currency format',
  howToUseCabal: 'How do you want to use Cabal?',
  businessFinance: 'Business finance',
  industry: 'Industry',
  selectIndustry: 'Select your industry',
  successUpdatedOrg: 'Successfully updated organization',
  successCreateddOrg: 'Organization successfully created',
  owner: 'Owner',
  guest: 'Guest',
  deleteOrganizationMessage: 'The organization will be deleted',
  deleteOrganizationButton: 'Delete organization',
  uploadCompleted: 'Upload completed',
  uploadFailed: 'Upload failed',
  processingInformation: 'Processing information',
  pleaseselectafile: 'Please select a file',
  uploadSuccess: 'Data received successfully.',
  startUpload: 'Start Upload',
  downloadTemplate: 'Download template',
  selectOrDragFile: 'Select or drag file here',
  fileType: 'Microsoft Excel (.xlsx)',
  assignProductsTo: 'Assign products to',
  assignSelection: 'Assign selection',
  moveToInProgress: 'Move to in progress',
  finalize: 'Finalize',
  finalized: 'Finalized',
  other: 'Other',
  ofthemonth: 'of the month',
  firstDayOfMonth: 'First day of the month',
  endOfMonth: 'End of the month',
  includeOffers: 'Include offers',
  hideOutOfStock: 'Hide out of stock products',
  insufficientStock: 'You do not have enough stock for this sale.',
  priceTooltip:
    'Use this field to sell this product at a different price. The inventory price will not be modified.',
  allUsers: 'All users',
  expenseEditedSuccessfully: 'Expense edited successfully',
  mustBeGreaterThanOrEqualToOne: 'Must be greater or equal to 1',
  valueRequired: 'This value is required',
  productPurchase: 'Product purchase',
  thisExpenseIsRecurrent: 'This expense is recurrent.',
  editExpense: 'Edit expense',
  rolesAndPermissions: 'Roles and permissions for your employees',
  offerCampaigns: 'Offer campaigns for the online store',
  multipleBusinesses: 'Multiple businesses in one account',
  profitReports: 'Profit and cash reports',
  confirmationEmail: 'Receive confirmation email from',
  subscriptionCanceled: 'Your subscription is canceled.',
  choosePlan: 'Then you can be Plus by choosing the plan that suits you best.',
  unlockPotential: 'Unlock your full potential with:',
  startingIsEasy: 'Starting is very easy:',
  startFreeTrialCTA: "Click the 'Start Free Trial' button.",
  receiveConfirmationEmail: 'Receive a confirmation email from',
  trialPeriodReminder: 'When your',
  trialDays: '7-DAY',
  trialEndingReminder:
    "trial is about to end, you'll receive a reminder and can complete your payment in your account.",
  cancelAnytimeCTA: 'Cancel anytime, no questions asked.',
  unlockYourFullPotential: 'Unlock your full potential',
  selectYourPlan: 'Select your plan',
  monthlyCTA: '/month',
  annual: '/year',
  threeMonths: '/month for 3 months',
  twentyPercentOff: '20% OFF',
  fiftySevenPercentOff: '57% OFF',
  normalPrice: 'After 3 months, normal price USD$6.99',
  cancelAnytimeCTA2: "Cancel anytime",
  monthlyv2: 'Monthly',
  annualv2: 'Annual',
};
