import { Paper, PaperProps, Typography } from '@mui/material';

import { useStrings } from 'src/hooks/useStrings';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '', ...other }: Props) {
  const { strings } = useStrings();

  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {strings('notFound')}
      </Typography>
      <Typography variant="body2" align="center">
        {strings('noResultsFoundFor')} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. {strings('checkTypos')}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2"> {strings('enterKeywords')}</Typography>
  );
}
