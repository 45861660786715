/**
 *
 * @deprecated please use DestructiveModal instead
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useStrings } from 'src/hooks/useStrings';
import { closeModal, getModalState } from 'src/redux/slices/deleteModal';
import { useDispatch, useSelector } from 'src/redux/store';

export default function DeleteDialog() {
  const { strings } = useStrings();
  const { itemName, isOpened, onDelete } = useSelector(getModalState);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog onClose={handleClose} open={isOpened as boolean}>
      <DialogTitle>{strings('areYouSure')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {strings('aboutToDelete')}{' '}
          <Typography component="span" variant="subtitle1">
            {itemName}
          </Typography>
          . {strings('cannotUndo')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{strings('cancel')}</Button>
        <Button
          id="confirm-delete-button"
          color="error"
          onClick={onDelete}
          autoFocus
        >
          {strings('confirmDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
