import moment, { Moment } from 'moment';

import { V1StripeChargesPostRequestPlanTypeEnum } from 'src/api-client';
import { TranslationKeys } from 'src/locales/es';

export interface SubscriptionOption {
  title: string;
  description: string;
  benefits: string[];
  best_value: boolean;
  cost: string;
  plan_type?: V1StripeChargesPostRequestPlanTypeEnum;
  image?: string;
  notes?: string;
}

export const offersStartDate: Moment = moment('09/29/2024 00:00');
export const offersEndDate: Moment = moment('10/31/2024 23:59');

const commonBenefits = ['Cancela cuando quieras'];

export const defaultPlans = (
  strings: (key: keyof TranslationKeys) => string
): SubscriptionOption[] => {
  commonBenefits[0] = strings('cancelAnytimeCTA2');
  const plans: SubscriptionOption[] = [
    {
      title: 'Cabal Plus',
      description: strings('monthly'),
      benefits: [...commonBenefits],
      plan_type: 'monthly',
      cost: 'USD$6.99',
      best_value: false,
    },
    {
      title: 'Cabal Plus',
      description: strings('annual'),
      benefits: [strings('twentyPercentOff'), ...commonBenefits],
      plan_type: 'anual',
      cost: 'USD$67.00',
      best_value: true,
    },
  ];

  return plans;
};

export const offerPlans = (
  strings: (key: keyof TranslationKeys) => string
): SubscriptionOption[] => {
  commonBenefits[0] = strings('cancelAnytimeCTA2');
  const plans: SubscriptionOption[] = [
    {
      title: 'Cabal Plus',
      description: strings('threeMonths'),
      benefits: [strings('fiftySevenPercentOff'), ...commonBenefits],
      plan_type: 'month_299' as V1StripeChargesPostRequestPlanTypeEnum,
      cost: 'USD$2.99',
      best_value: true,
      notes: strings('normalPrice'),
    },
    {
      title: 'Cabal Plus',
      description: strings('annual'),
      benefits: [strings('twentyPercentOff'), ...commonBenefits],
      plan_type: 'anual',
      cost: 'USD$67.00',
      best_value: false,
    },
  ];

  return plans;
};

export const hasOffersEnabled = () => {
  const todaysMoment = moment();
  const enableOffers =
    todaysMoment >= offersStartDate && todaysMoment < offersEndDate;

  return enableOffers;
};
