import { IconDefinition, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { List } from 'src/components/List';
import { useStrings } from 'src/hooks/useStrings';
import { TranslationKeys } from 'src/locales/es';

import { SubscriptionOption } from '../data/subscriptionTypes';

type Props = {
  plan: SubscriptionOption;
  onClick: (plan: SubscriptionOption) => void;
  disabled: boolean;
};

const ContentStyle = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  height: 'calc(100% - 40px)',
  marginTop: 40,
  '&.featured': {
    color: 'white',
    height: '100%',
    marginTop: 0,
    background: theme.palette.secondary.main,
  },
  '& .inner': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius / 2,
  },
  '& ul': {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  '& button': {
    marginTop: theme.spacing(3),
  },
}));

type BenefitListItemProps = {
  description: ReactNode;
  icon: IconDefinition;
  alt?: boolean;
};

const BenefitListItem = ({ description, icon, alt }: BenefitListItemProps) => (
  <Stack spacing={1} direction="row" alignItems="center">
    <div>
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          background: '#ccbfd7',
          width: 28,
          height: 28,
          borderRadius: 60,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          color="#552b7c"
          style={{ display: 'block', fontSize: 14 }}
        />
      </Stack>
    </div>

    {typeof description === 'string' ? (
      <Typography
        fontSize={14}
        paragraph
        marginBottom={0}
        style={{
          color: alt ? 'white' : '#212b36',
        }}
      >
        {description}
      </Typography>
    ) : (
      description
    )}
  </Stack>
);

export const PlanDescription = ({ plan, onClick, disabled }: Props) => {
  const currency = plan.cost.substring(0, 3);
  const cost = plan.cost.substring(3);
  const { strings } = useStrings();
  
  return (
    <ContentStyle className={plan.best_value ? 'featured' : ''}>
      <div className="inner">
        <div>
          {plan.image && <img alt={plan.title} src={plan.image} />}
          <Typography
            variant={plan.best_value ? 'h4' : 'h5'}
            style={{
              lineHeight: '28px',
            }}
          >
            {plan.title}
          </Typography>
          <Chip
            size="small"
            color={plan.best_value ? 'success' : 'secondary'}
            variant={plan.best_value ? 'filled' : 'outlined'}
            label={
              plan.plan_type?.includes('month')
                ? strings('monthlyv2')
                : strings('annualv2')
            }
          />
          <Typography style={{ fontSize: 12, marginTop: 4 }}>
            {currency}
            <Typography
              component="span"
              style={{ fontSize: 24, fontWeight: 'bold' }}
            >
              {cost}
            </Typography>
            {plan.description}
          </Typography>
          <List
            data={plan.benefits}
            gridProps={{
              marginTop: 1,
              spacing: 1,
            }}
            gridItemProps={{
              xs: 12,
            }}
            renderItem={(benefit) => (
              <BenefitListItem
                description={benefit}
                icon={faCheck}
                alt={plan.best_value}
              />
            )}
          />
          {plan.notes && (
            <Typography
              marginTop={2}
              variant="caption"
              textAlign="center"
              style={{ display: 'block', opacity: 0.5 }}
            >
              {plan.notes}
            </Typography>
          )}
        </div>

        <Button
          fullWidth
          onClick={() => onClick(plan)}
          variant={plan.best_value ? 'contained' : 'outlined'}
          size={plan.best_value ? 'large' : 'medium'}
          disabled={disabled}
        >
          {plan.cost}
        </Button>
      </div>
    </ContentStyle>
  );
};
