import { yupResolver } from '@hookform/resolvers/yup';
import { Check } from '@mui/icons-material';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { UsersApi } from 'src/api-client';
import { SubmitButton } from 'src/components/SubmitButton';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useOnError } from 'src/hooks/useOnError';
import { useStrings } from 'src/hooks/useStrings';

type SignUpFormFields = {
  email: string;
  password: string;
  confirm_password: string;
};

export const SignUpForm = () => {
  const { sendAnalyticsEvent } = useAnalytics();
  const [success, setSuccess] = useState(false);
  const { onError } = useOnError();
  const { strings } = useStrings();

  const schema = Yup.object().shape({
    email: Yup.string().email().required(strings('emailRequired')),
    password: Yup.string().required(strings('passwordRequired')),
    confirm_password: Yup.string().oneOf(
      [Yup.ref('password'), null],
      strings('passwordsMustMatch')
    ),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (user: SignUpFormFields) =>
      UsersApi.usersPost({
        usersPostRequest: {
          user,
        },
      }),
    onSuccess: () => {
      setSuccess(true);
    },
    onError,
  });

  const methods = useForm<SignUpFormFields>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: SignUpFormFields) => {
    sendAnalyticsEvent('sign_up_email');
    mutateAsync(data);
  };

  if (success) {
    return (
      <>
        <Check
          style={{
            color: 'white',
            fontSize: 64,
            backgroundColor: '#48B223',
            padding: 20,
            borderRadius: 100,
            boxShadow: '0px 0px 0px 5px #C7EFB9',
            margin: '0 auto 20px',
            display: 'block',
          }}
        />
        <Alert severity="success" icon={<span />}>
          <Typography fontSize={13} textAlign="center">
            {strings('emailSent')}
          </Typography>
        </Alert>
      </>
    );
  }

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RHFTextField
            size="small"
            id="email"
            name="email"
            label={strings('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            size="small"
            id="password"
            name="password"
            label={strings('password')}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            size="small"
            id="confirmPassword"
            name="confirm_password"
            label={strings('confirmPassword')}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            label={strings('createAccount')}
            type="button"
            fullWidth
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
