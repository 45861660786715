import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { CabalPlusManualModal } from 'src/components/CabalPlusCTA/ModalManual';
import { useStrings } from 'src/hooks/useStrings';

export const PromoBanner = () => {
  const { strings } = useStrings();

  return (
    <CabalPlusManualModal
      customRender={
        <Card
          style={{
            borderRadius: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#FFF179',
          }}
        >
          <CardActionArea
            style={{
              padding: 10,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Typography marginBottom={0} sx={{ color: 'secondary.main' }}>
              {strings('cabalPlusPrice3Months')}{' '}
              <span style={{ textDecoration: 'line-through' }}>
                {strings('cabalPlusMonthlyPrice')}
              </span>
            </Typography>
          </CardActionArea>
        </Card>
      }
    />
  );
};
