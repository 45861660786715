import { Check } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { VariantType, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { useAnalytics } from 'src/hooks/useAnalytics';
import useAuth from 'src/hooks/useAuth';
import { useStrings } from 'src/hooks/useStrings';

import { SubscriptionOption } from './JoinCabalPlus/data/subscriptionTypes';

type Props = {
  isDirectBuy?: boolean;
  selectedPlan: SubscriptionOption;
};

export const CabalPlusForm = ({ isDirectBuy, selectedPlan }: Props) => {
  const { sendAnalyticsEvent } = useAnalytics();
  const { user, updateUser: refreshUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const { strings } = useStrings();

  useEffect(() => {
    sendAnalyticsEvent('user_view_purchase_form');
  }, []);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      enqueueSnackbar(result.error.message ?? strings('unexpectedError'), {
        variant: 'error',
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      let message = '';
      let variant: VariantType = 'success';

      switch (result.paymentIntent.status) {
        case 'succeeded':
          sendAnalyticsEvent('subscription_plus_purchased');
          message = strings('unlockYourPotentialNow');
          setShowConfirmation(true);
          break;

        case 'processing':
          message = strings('processingPayment');
          break;

        case 'requires_payment_method':
          message = strings('paymentFailed');
          variant = 'error';
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          break;

        default:
          message = strings('unexpectedError');
          variant = 'error';
          break;
      }

      enqueueSnackbar(message, {
        variant,
      });

      setIsLoading(false);
    }
  };

  const handleContinue = () => {
    if (user) {
      setShowConfirmation(false);
      refreshUser({
        ...user,
        current_organization: {
          ...user.current_organization,
          has_paid_access: true,
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          disabled={!stripe || isLoading}
          style={{ marginTop: 20 }}
          onClick={() =>
            sendAnalyticsEvent('user_clicked_purchase_form_submit')
          }
        >
          {strings('subscribeFor')} {selectedPlan.cost}
        </Button>
      </form>

      <Dialog open={showConfirmation} onClose={handleContinue}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <Check
            style={{
              color: 'white',
              fontSize: 64,
              backgroundColor: '#48B223',
              padding: 20,
              borderRadius: 100,
              boxShadow: '0px 0px 0px 5px #C7EFB9',
              marginBottom: 20,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography>{strings('unlockYourPotentialNow')}</Typography>
        </DialogContent>
        <DialogActions style={{ paddingTop: 0 }}>
          <Button fullWidth onClick={handleContinue} variant="contained">
          {strings('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
