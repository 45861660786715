import { Delete, Help } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { ACCProduct, ACCService } from 'src/@types/cart';
import {
  getCartState,
  updatePrice,
  updateQuantity,
} from 'src/redux/slices/cart';
import { dispatch } from 'src/redux/store';
import { fCurrency } from 'src/utils/formatNumber';

import { CartTransactionEnum } from '.';
import IncrementerButton from '../custom-input/IncrementerButton';
import { useStrings } from 'src/hooks/useStrings';

type Props = {
  item: ACCProduct | ACCService;
  idx: number;
  transactionType: CartTransactionEnum;
  itemType: 'products' | 'services';
  removeItem: VoidFunction;
  disablePriceField?: boolean;
  originalQtyInSale?: number;
};

export const exceptThisSymbols = ['e', 'E', '+', '-'];

export const PriceModifier = ({
  item,
  transactionType,
  itemType,
  removeItem,
  idx,
  disablePriceField,
  originalQtyInSale,
}: Props) => {
  const { strings } = useStrings();
  const itemAsProduct = item as ACCProduct;
  const { products, services } = useSelector(getCartState);
  const { enqueueSnackbar } = useSnackbar();
  const showInvalidStockMessage = () => {
    enqueueSnackbar(strings('insufficientStock'), {
      variant: 'error',
    });
  };

  const { quantity: itemInCartQty } =
    itemType === 'products' ? products[idx] : services[idx];
  let allInCartQtySum = 0;
  const productsInCart = products.filter((p) => p.id === item.id);

  if (productsInCart.length > 0) {
    allInCartQtySum = productsInCart
      .map((product) => product.quantity)
      .reduce((curr, next) => curr + next);
  }

  const handleNewPrice = (price: string) => {
    dispatch(
      updatePrice({
        idx,
        price,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityChange = (quantity: number) => {
    if (itemType === 'products') {
      const { id, stock } = itemAsProduct;
      let quantityInCart = 0;
      let productsInCart = products.filter((p) => p.id === id);
      if (productsInCart.length > 0) {
        quantityInCart = productsInCart
          .map((p) => p.quantity)
          .reduce((prev, curr) => prev + curr);
      }
      let newQuantity = quantityInCart + 1;
      let canAddToCart = stock >= newQuantity;

      if (originalQtyInSale) {
        canAddToCart = stock + originalQtyInSale >= newQuantity;
      }

      if (!canAddToCart) {
        if (newQuantity > stock + (originalQtyInSale ?? 0)) {
          showInvalidStockMessage();
        }
        return;
      }
    } else {
    }

    dispatch(
      updateQuantity({
        idx,
        quantity,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityIncrease = () => handleQuantityChange(item.quantity + 1);

  const handleQuantityDecrease = () => {
    dispatch(
      updateQuantity({
        idx,
        quantity: item.quantity - 1,
        transactionType,
        itemType,
      })
    );
  };

  const onMaxReached = () => {
    showInvalidStockMessage();
  };

  let max: number | undefined;

  if (itemType === 'products') {
    max = itemAsProduct.stock + itemInCartQty - allInCartQtySum;

    if (originalQtyInSale) {
      max =
        itemAsProduct.stock +
        originalQtyInSale +
        itemInCartQty -
        allInCartQtySum;
    }
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <Stack spacing={1} marginBottom={2} direction="row" alignItems="center">
        <IconButton size="small" color="error" onClick={() => removeItem()}>
          <Delete fontSize="small" />
        </IconButton>
        <Tooltip title={item.name}>
          <Typography noWrap fontSize={18} fontWeight="bold" marginBottom={1}>
            {item.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        {!disablePriceField && (
          <Grid item xs={12} sm={6}>
            {itemType === 'products' ? (
              <TextField
                type="number"
                label={strings('salePrice')}
                value={item.price}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={strings('priceTooltip')}>
                        <IconButton>
                          <Help />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                onChange={(e) => handleNewPrice(e.target.value)}
              />
            ) : (
              <Typography variant="body1">
                {strings('price')}: {fCurrency(item.price)}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <IncrementerButton
            value={item.quantity}
            max={itemType === 'products' ? max : undefined}
            onMaxReached={onMaxReached}
            onQtyChange={(quantity) => handleQuantityChange(quantity)}
            disabledDecrease={item.quantity <= 1}
            onIncrease={handleQuantityIncrease}
            onDecrease={handleQuantityDecrease}
          />
        </Grid>
      </Grid>
    </div>
  );
};
