export const general = {
  voiceWelcome:
    '¡Hola! Bienvenido a Cabal AI, da click en el botón y dí algo como "Crear un gasto de 10 dólares"',
  voiceMicPermission: 'Necesitamos permisos para hacer uso de tu micrófono.',
  voiceNoMic: 'Necesitas un micrófono para hacer uso de esta funcionalidad.',
  voiceTimeLimit:
    'El audio que has grabado supera los %maxSecs segundos, por favor intenta ser mas breve.',
  voiceExpenseCreated: 'Gasto creado con éxito',
  voiceProcessing: 'Procesando...',
  accountUnableToLogout: '¡No se puede cerrar sesión!',
  accountProfile: 'Tu perfil',
  accountLogOut: 'Cerrar Sesión',
  whatsNewTitle: 'Actualizaciones recientes',
  whatsNewNocontent: 'No hay datos disponibles',
  whatsNewError: 'Ocurrió un error cargando la información',
  whatsNewPlublishedOn: 'Publicado el',
  close: 'Cerrar',
  edit: 'Editar',
  loading: 'Cargando',
  search: 'Buscar',
  sort: 'Ordenar',
  filter: 'Filtrar',
  by: 'por',
  name: 'Nombre',
  return: 'Regresar',
  barcode: 'Código de barras',
  none: 'Ninguno',
  priceUp: 'Mayor precio',
  priceDown: 'Menor precio',
  new: 'Nuevo',
  new2: 'Nueva',
  newer: 'Más reciente',
  older: 'Más antiguo',
  price: 'Precio',
  cost: 'Costo',
  add: 'Agregar',
  buy: 'Comprar',
  more: 'más',
  apply: 'Aplicar',
  next: 'Siguiente',
  prev: 'Anterior',
  youHave: 'Tienes',
  order: 'Orden',
  employees: 'Empleados',
  delete: 'Eliminar',
  cancel: 'Cancelar',
  continue: 'Continuar',
  writeConfirmation: 'Para continuar por favor escribe',
  selectAll: 'Seleccionar todos',
  cancelSelection: 'Cancelar selección',
  deleteSelection: 'Eliminar selección',
  bulkSelect: 'Selección multiple',
  outOfStock: 'Stock agotado',
  lowStock: 'Stock bajo',
  createNew: 'Crear nuevo',
  createNew2: 'Crear nueva',
  requestReport: 'Solicitar reporte',
  date1: 'Fecha',
  date2: 'Fechas',
  from: 'Desde',
  until: 'Hasta',
  imageUploaderTitle: 'Selecciona las imágenes',
  imageUploaderDescription:
    'Arrastra los archivos o da click acá para elegir las imágenes',
  imageUploaderRejection:
    'Verifica la cantidad máxima de archivos, he intenta de nuevo.',
  imageUploaderValidation: 'La cantidad máxima de imágenes es 5',
  required: 'Requerido',
  limit: 'Limite',
  image: 'imagen',
  images: 'imágenes',
  max: 'max',
  currentImages: 'Imágenes actuales',
  imageDeleteConfirmation: '¿Estás seguro que deseas eliminar esta imagen?',
  deleteConfirmation: 'Por favor confirma para continuar.',
  saveChanges: 'Guardar cambios',
  save: 'Guardar',
  confirm: 'Confirmar',
  important: 'Importante',
  regenerate: 'Regenerar',
  regenerateWarning:
    'Los servicios que tengas integrados dejarán de funcionar.Luego de regenerar el API Key actualiza todas las integraciones que hayas hecho con el nuevo código para evitar inconvenientes.',
  apiKey: 'API Key',
  noApiKey: 'No cuentas con una API Key activa.',
  copy: 'Copiar',
  copied: 'Copiado!',
  counterSale: 'Venta mostrador',
  pointOfSaleInfo:
    'En el Punto de Ventas podrás realizar ventas al contado tipo mostrador. Si deseas realizar ventas al crédito ve al módulo Contabilidad.',
  goToAccounting: 'Ir a Contabilidad',
  unexpectedError: 'Ocurrió un error inesperado cargando este módulo.',
  inconvenienceMessage:
    'Lamentamos los inconvenientes, por favor inténtalo de nuevo más tarde.',
  date: 'Fecha',
  orderCode: 'Código de la orden',
  total: 'Total',
  customer: 'Cliente',
  phone: 'Teléfono',
  address: 'Dirección',
  items: 'Items',
  freeSale: 'Venta libre',
  discount: 'Descuento',
  paymentMethod: 'Método de pago',
  cash: 'Contado',
  credit: 'Crédito',
  thankYou: '¡Gracias por su compra!',
  appDescription: 'la app de los emprendedores, disponible para Android y iOS.',
  notFound: 'No encontrado',
  checkTypos:
    'Intenta verificar si hay errores de escritura o usa palabras completas.',
  enterKeywords: 'Por favor ingresa palabras clave',
  noResultsFoundFor: 'No se encontraron resultados para',
  quoteRegisteredSuccess: 'Cotización registrada con éxito',
  quoteReadyMessage: 'La cotización está lista, ¿Qué deseas hacer?',
  viewQuote: 'Ver cotización',
  download: 'Descargar',
  saleRegisteredSuccess: 'Venta registrada con éxito',
  printOrShareReceipt: '¿Deseas imprimir/compartir el recibo?',
  print: 'Imprimir',
  permissionDenied: 'Permiso denegado',
  noAccess: 'No tienes permisos para acceder a esta página.',
  emailNotification: 'Recibirás un correo electrónico',
  areYouSure: '¿Estás seguro?',
  aboutToDelete: 'Estás a punto de eliminar',
  cannotUndo: 'No podrás deshacer esta acción.',
  confirmDelete: 'Entiendo y deseo eliminarlo.',
  nameRequired: 'Nombre es requerido',
  validEmailRequired: 'Debe ser un email válido',
  phoneRequired: 'Teléfono es requerido',
  createContact: 'Crea el contacto',
  email: 'Email',
  createContactAction: 'Crear contacto',
  or: 'o',
  chooseContact: 'Elige un contacto',
  searchContact: 'Buscar contacto',
  seeMore: 'Ver más',
  authorizationRequired: 'Autorización requerida',
  requestAccess: 'Por favor solicita acceso a',
  cabalPlus: 'Cabal Plus',
  organizationOwner: 'al dueño de la organización.',
  acquireCabalPlus: 'Adquirir Cabal Plus',
  unexpectedErrorReportSent:
    'Ocurrió un error inesperado, un reporte ha sido enviado, corregiremos el problema a la brevedad.',
  selectPlaylist: 'Seleccionar lista de reproducción',
  playlists: 'Listas',
  dragOrClickToSelectImage:
    'Arrastra los archivos o da click acá para elegir la imagen.',
  viewReceipt: 'Ver recibo',
  downloadReceipt: 'Descargar recibo',
  generating: 'Generando...',
  show: 'Mostrar',
  filterByUser: 'Filtrar por usuario',
  all: 'Todos',
  productImage: 'Imagen de producto',
  uploadImage: 'Subir imagen',
  selectFromGallery: 'Seleccionar de galería',
  selectDateRange: 'Selecciona rango de fechas',
  input: 'input',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  endDateGreater: 'La fecha final debe ser mayor que la inicial.',
  updateProductCost:
    'Utiliza este campo para actualizar el costo de este producto.',
  viewExpense: 'Ver gasto',
  startFreeTrial: 'Inicia prueba gratuita',
  welcomeToCabalPlus: '¡Listo, Bienvenido a Cabal Plus',
  processingPayment:
    'Procesando pago. Te notificaremos cuando recibamos tu pago.',
  paymentFailed: 'Pago fallido. Por favor inténtalo con otra forma de pago.',
  subscribeFor: 'Suscribirse por',
  buyNow: 'Comprar ahora',
  trialEndsIn: 'Tu período de prueba finalizará en',
  cabalPlusPrice3Months: 'Cabal Plus USD$2.99 x 3 Meses',
  cabalPlusMonthlyPrice: 'USD$6.99 Mes',
  employeeRolesPermissions: 'Roles y permisos para tus empleados',
  salesPlanForFinance: 'Plan de ventas para las finanzas',
  offerCampaignsForStore: 'Campañas de ofertas para la tienda en línea',
  multipleBusinessesInOneAccount: 'Múltiples negocios en una sola cuenta',
  profitAndCashReports: 'Reportes de ganancias y efectivo',
  clickStartFreeTrial: 'Dale click al botón de "Iniciar prueba gratuita".',
  confirmationEmailReceived: 'Recibe correo de confirmación de',
  trialReminder:
    'Cuando tu prueba de 7 DÍAS esté por vencer recibirás un recordatorio, y podrás completar tu pago dentro de tu usuario',
  cancelAnytime:
    'Cancela en cualquier momento que desees, sin explicaciones ni complicaciones.',
  subscriptionExpired: 'Tu suscripción ha expirado.',
  subscriptionCancelled:
    'Tu suscripción ha sido cancelada porque tu pago falló.',
  trialExpired: 'Tu período de prueba ha expirado.',
  subscriptionCancelledStatus: 'Tu suscripción está cancelada.',
  startFreeTrial7Days:
    'Comienza ahora la prueba gratuita de 7 DÍAS, SIN ingresar tarjeta de crédito/débito.',
  choosePlanAfterTrial:
    'Luego podrás ser Plus eligiendo el plan que mejor te convenga.',
  unlockYourPotential: 'Desbloquea tu máximo potencial con:',
  manyMoreFeatures: 'Y muchas funciones más',
  easyToStart: 'Comenzar es muy fácil:',
  unlockYourPotentialNow: '¡Listo, bienvenido a Cabal Plus!',
  selectPlan: 'Selecciona tu plan',
  changePlan: 'Cambiar plan',
  errorOccurred: 'Ocurrió un error',
  after3MonthsNormalPrice: 'Luego de 3 meses, precio normal USD$6.99',
  pricePlans: '/año /mes por 3 meses /mes',
  cancelAnytimePlan: 'Cancela cuando quieras',
  getCabalPlus: 'Adquiere Cabal Plus',
  day: 'día',
  days: 'días',
  pageNotFound: '404 Página No Encontrada',
  sorryPageNotFound: 'Lo sentimos, página no encontrada!',
  pageNotFoundMessage:
    'Lo sentimos, no pudimos encontrar la página que estás buscando. ¿Quizás escribiste mal la URL? Asegúrate de verificar la ortografía.',
  goToHome: 'Ir a Inicio',
  forbidden403: '403 Prohibido',
  noPermission: 'Sin permiso',
  restrictedAccessMessage:
    'La página a la que intentas acceder tiene acceso restringido.',
  contactAdmin: 'Por favor, consulta con tu administrador del sistema.',
  joinCabalPlus: 'Únete a Cabal Plus',
  unableToLogout: '¡No se puede cerrar sesión!',
  createMyBusiness: 'Quiero crear mi negocio',
  personalFinance: 'Finanzas Personales',
  joinBusinessAsGuest: 'Quiero unirme a un negocio como invitado',
  goBack: 'Regresar',
  almostReady: '¡Ya casi estamos listos!',
  loginWithNewPassword: 'Por favor inicia sesión con tu nueva contraseña.',
  loginToContinueAccountCreation:
    'Por favor inicia sesión para continuar con el proceso de creación de cuenta.',
  emailConfirmationError: 'Ocurrió un error confirmando tu correo electrónico.',
  login: 'Iniciar Sesión',
  resetPassword: 'Restaurar Contraseña',
  recoverPassword: 'Recuperar contraseña',
  changePassword: 'Cambiar Contraseña',
  createAccount: 'Crear Cuenta',
  loginOrCreateAccountToJoin:
    'Por favor inicia sesión o crea una cuenta para unirte a',
  earnWithCabal: '¡Gana con Cabal!',
  referOthers:
    'Refiere a otros que utilicen Cabal y si ellos se convierten en Plus,',
  clickToStartEarning: '¡Haz click y comienza a ganar!',
  verifyEmail: 'Verificar email',
  emailVerified: 'Correo electrónico verificado correctamente',
  monthlyCommission: 'te llevas el 40% de comisión ¡todos los meses!',
  webSales: 'Ventas web',
  incoming: 'Entrantes',
  inProgress: 'En progreso',
  completed: 'Finalizados',
  webSaleRejected: 'Venta web rechazada con éxito',
  webSaleApproved: 'Venta web aprobada con éxito',
  sale: 'Venta',
  webSale: 'Venta Web',
  dashboard: 'Dashboard',
  reject: 'Rechazar',
  coupon: 'Cupón',
  contact: 'Contacto',
  creationDate: 'Fecha de creación',
  item: 'Item',
  quantity: 'Cantidad',
  priceWithDiscount: 'Precio con descuento',
  tutorials: 'Tutoriales',
  back: 'Regresar',
  id: 'ID',
  saleDate: 'Fecha de venta',
  product: 'Producto',
  unitPrice: 'Precio unitario',
  status: 'Estado',
  actions: 'Acciones',
  viewDetails: 'Ver detalles',
  confirmDeletion: 'Confirmar eliminación',
  confirmDeletionMessage: '¿Estás seguro de que deseas eliminar esta venta?',
  accept: 'Aceptar',
  receiptGenerated: 'Recibo generado',
  salesReceipt: 'Recibo de venta',
  paymentReminder: 'Recordatorio de pago',
  pending: 'Pendiente',
  loaded: 'Cargado',
  concept: 'Concepto',
  quantityShort: 'Cant.',
  services: 'Servicios',
  products: 'Productos',
  costOfSales: 'Costo de ventas',
  earnings: 'Ganancias',
  registerCharge: 'Registrar cargo',
  newSale: 'Nueva venta',
  accountingSalesInfo:
    'En Contabilidad podrás realizar ventas al contado y crédito. Para generar órdenes / comandas para ventas tipo mostrador, ve al módulo PUNTO DE VENTAS.',
  goToPOS: 'Ir a PDV',
  charges: 'Cargos',
  editIncome: 'Editar Ingreso',
  deposits: 'Abonos',
  costOfIncome: 'Costo de Ingresos',
  registerDeposit: 'Registrar abono',
  newIncome: 'Nuevo ingreso',
  recurringExpense: 'Gasto recurrente',
  newExpense: 'Nuevo gasto',
  income: 'Ingresos',
  expenses: 'Gastos',
  newCategory: 'Nueva Categoria',
  transactionCategory: 'Categoría de transacción',
  editTransactionCategory: 'Editar categoría de transacción',
  transactionCategories: 'Categoría de transacciones',
  totalTransactions: 'Total de transacciones:',
  viewTransactions: 'Ver transacciones',
  deposit: 'Abono',
  transactionCategoryedit: 'Categoría de transacción | Editar',
  type: 'Tipo',
  description: 'Descripción',
  paymentType: 'Tipo de pago',
  amount: 'Monto',
  transactionCategoriesnew: 'Categorías de transacciones | Nuevo',
  newTransactionCategory: 'Nueva categoría de transacción',
  storeSettings: 'Configuración de Tienda',
  yourStore: 'Tu tienda',
  paymentSettings: 'Configuración de pagos',
  orders: 'Pedidos',
  inventory: 'Inventario',
  hello: 'Hola',
  whatDoYouWantToDoToday: '¿Qué quieres hacer hoy?',
  openStore: 'Abrir tienda',
  shareMyStore: 'Compartir mi tienda',
  showQrCodeOfMyStore: 'Mostrar código QR de mi tienda',
  copyUrl: 'copiar url',
  manageYourOnlineStore: 'Controla tu tienda en línea',
  productsView: 'Productos | Ver',
  productDetails: 'Detalle de producto',
  productNotFound: '404 Producto no encontrado',
  productCategoriesAll: 'Categoría de productos | Todos',
  wantToSearchSomething: '¿Deseas buscar algo?',
  productCategoryEdit: 'Categoría de producto | Editar',
  assignProducts: 'Asignar productos',
  productCategoryNew: 'Categoría de producto | Nuevo',
  newProductCategory: 'Nueva categoría de producto',
  productCategories: 'Categoría de productos',
  sales: 'Ventas',
  costs: 'Costos',
  generalHome: 'General: Inicio',
  detailsAll: 'Detalles | Todos',
  cashClosing: 'Corte de caja',
  noteTransactionsBeforeSeptember:
    'NOTA: Todas las transacciones hechas antes de la última semana de Septiembre fueron asignadas al/la dueño/a de la organización',
  client: 'Cliente',
  outstandingDebts: 'Deudas pendientes',
  totalAmount: 'Monto total',
  chargesPayments: 'Cargos / Abonos',
  debtsByUser: 'Deudas por usuario',
  loadMore: 'Cargar más',
  debtsByTransaction: 'Deudas por transacción',
  searchByName: 'Buscar por nombre',
  expense: 'Gasto',
  code: 'Código',
  payment: 'Abono',
  searchByCode: 'Buscar por código',
  registerPaymentToTransaction: 'Registrar abono a transacción',
  totalDebt: 'Deuda total',
  paid: 'Pagado',
  user: 'Usuario',
  charge: 'Cargo',
  toTransaction: 'a transacción',
  received: 'Recibido',
  register: 'Registrar',
  serviceCategoryAll: 'Categoría de servicio | Todos',
  serviceCategory: 'Categoría de servicio',
  serviceCategoryEdit: 'Categoría de servicio | Editar',
  editServiceCategory: 'Editar categoría de servicio',
  serviceCategoryNew: 'Categoría de servicio | Nuevo',
  newServiceCategory: 'Nueva categoría de servicio',
  inventoryCreateNewService: 'Inventario: Crea un nuevo servicio',
  createNewService: 'Crea un nuevo servicio',
  newService: 'Nuevo servicio',
  serviceEdit: 'Servicio | Editar',
  editService: 'Editar servicio',
  servicesAll: 'Servicios | Todos',
  rolesPermissions: 'Roles y permisos',
  quotes: 'Cotizaciones',
  createQuote: 'Crear cotización',
  quote: 'Cotización',
  expirationDate: 'Fecha de expiración',
  validity: 'Válidez',
  inventoryAnalytics: 'Analíticas Inventario',
  updateSale: 'Actualizar venta',
  doYouWantToPrintReceipt: '¿Deseas imprimir el recibo?',
  no: 'No',
  yes: 'Sí',
  ordersCommands: 'Órdenes / Comandas',
  viewWebOrdersHere:
    'Ahora puedes visualizar tus órdenes web en esta pantalla, configúralo en Personalización.',
  goToCustomization: 'Ir a Personalización',
  importData: 'Importar datos',
  noSellRentData:
    'No vendemos, alquilamos ni utilizamos su base de datos para fines comerciales.',
  uploadHistory: 'Historial de cargas',
  organizations: 'Organizaciones',
  successfullyJoined: 'Te has unido con éxito',
  invitationCode: 'Código de la invitación',
  logout: 'Cerrar sesión',
  yesJoin: 'Sí, unirme',
  exit: 'Salir',
  notPartOfBusiness:
    'Por el momento no eres parte de un negocio. Si tienes un código de invitación úsalo en el formulario de abajo.',
  findInvitationCode:
    'Puedes encontrar el código de tu invitación en tu correo electrónico.',
  customizeOrganization: 'Personalizar organización',
  joinTo: 'Unirme a',
  hellov2: '¡Hola',
  joinOrganization: '¿Deseas unirte a la organización',
  withEmail: 'con el correo',
  join: 'Unirme',
  extrasNew: 'Adicionales | Nuevo',
  extrasEdit: 'Adicionales | Editar',
  extrasAll: 'Adicionales | Todos',
  viewMore: 'Ver más',
  financialAssistant: 'Asistente Financiero',
  financialStatements: 'Estados Financieros',
  financialStatementsDescription:
    'Recuerda que los estados financieros resumen tu desempeño y los recursos que posees, adeudas y acumulas.',
  indicatorsDescription:
    'Los indicadores son la mejor forma de medir tu desempeño, dichos ratios se derivan de los estados financieros y permite evaluar la salud de tu negocio.',
  wantToLearnMore: '¿Quiéres aprender más?',
  channelHelpMessage:
    'Recuerda que nuestro canal te ayuda con el mejor contenido para manejar tu negocio.',
  financialIndicators: 'Indicadores Financieros',
  contacts: 'Contactos',
  newContact: 'Nuevo contacto',
  sortBy: 'Ordenar por',
  transactionCount: 'Número de transacciones',
  transactions: 'Transacciones',
  debt: 'Deuda',
  contactEdit: 'Contacto | Editar',
  productsByUser: 'Productos por usuario',
  transactionsByUser: 'Transacciones por usuario',
  chargesCredits: 'Cargos / Abonos',
  registerPayment: 'Registrar abono a transacción',
  verifyAccount: 'Verificar cuenta',
  salesInCash: 'Ventas al contado',
  salesCredit: 'Ventas al crédito',
  salesOnline: 'Ventas en línea',
  shopifySales: 'Ventas Shopify',
  shopifyCosts: 'Costos Shopify',
  discounts: 'Descuentos totales',
  costsTotal: 'Costos totales',
  expensesInCash: 'Gastos al contado',
  expensesCredit: 'Gastos al crédito',
  lowStockProducts: 'Productos con bajo stock',
  zeroStockProducts: 'Productos sin stock',
  skippedCosts: 'Compra de productos',
  depositsTotal: 'Abonos totales',
  availableCash: 'Ahorro',
  incomeReceived: 'Ingresos recibidos',
  pendingIncome: 'Ingresos pendientes',
  paidExpenses: 'Gastos pagados',
  pendingExpenses: 'Gastos pendientes',
  myFinances: 'Mis finanzas',
  weekly: 'Semanal',
  creditSales: 'Ventas al crédito',
  cashSales: 'Ventas al contado',
  accountsReceivablePayable: 'Cuentas por cobrar y por pagar',
  incomeStatement: 'Estado de resultados',
  webOrdersSummary: 'Resumen de ordenes web',
  inventoryDetail: 'Detalle de inventario',
  monthly: 'Mensuales',
  sortByWeek: 'Ordenar por semana',
  sortByMonth: 'Ordenar por mes',
  viewAll: 'Ver todas',
  viewTransaction: 'Ver transacción',
  onlineSales: 'Ventas en línea',
  totalSales: 'Venta total',
  cashCost: 'Costo al contado',
  creditCost: 'Costo al crédito',
  onlineCost: 'Costo en línea',
  cashExpenses: 'Gastos al contado',
  creditExpenses: 'Gastos al crédito',
  accountingLoss: 'Pérdida contable',
  accountingProfit: 'Utilidad contable',
  finished: 'Finalizadas',
  creditSalesPayments: 'Abonos ventas al crédito',
  productPurchases: 'Compra de productos',
  creditExpensesPayments: 'Abono gastos al crédito',
  productsServices: 'Productos/Servicios',
  categories: 'Categorías',
  totalInventoryCost: 'Costo total de inventario',
  totalInventoryValue: 'Valor total de inventario',
  week: 'semana',
  month: 'mes',
  orderBy: 'Ordenar por',
  coupons: 'Cupones',
  yourOffers: 'Tus ofertas',
  editDiscount: 'Editar descuento',
  duplicateDiscount: 'Duplicar Descuento',
  editCoupon: 'Editar Cupón',
  duplicateCoupon: 'Duplicar Cupón',
  createAccountOrLogin: 'Crea tu cuenta o inicia sesión usando Google o Apple.',
  passwordRecovery: 'Recuperar contraseña',
  useEmail: 'o utiliza tu correo electrónico:',
  emailRequired: 'Tu dirección de correo electrónico es requerida',
  passwordRequired: 'Por favor escribe una contraseña',
  passwordsMustMatch: 'Contraseñas deben ser iguales',
  password: 'Contraseña',
  confirmPassword: 'Confirmar contraseña',
  emailSent:
    'Te hemos envíado un correo electrónico para confirmar tu cuenta. Por favor sigue las instrucciones para continuar.',
  passwordUpdated: 'Contraseña actualizada',
  newPassword: 'Nueva contraseña',
  validEmail: 'Debes ingresar un correo electrónico válido',
  send: 'Envíar',
  currentPassword: 'Contraseña actual',
  firstName: 'Nombre',
  lastName: 'Apellido',
  invitationCodeTooltip:
    'Puedes encontrar el código de tu invitación en tu correo electrónico.',
  loggedInAs: 'Has iniciado sesión con',
  finishRegistration: 'Finalizar registro',
  firstNameRequired: 'Tu Nombre es requerido',
  lastNameRequired: 'Tu apellido es requerido',
  currentPasswordRequired: 'Por favor escribe tu contraseña actual',
  newPasswordRequired: 'Por favor escribe tu nueva contraseña',
  changesSaved: '¡Cambios guardados!',
  searchCategory: 'Search category',
  categoryRequired: 'Category is required',
  categoryModified: 'Category modified successfully',
  categoryCreated: 'Category created successfully',
  notEditable: 'This category is not editable.',
  categoryType: 'Category type',
  categoryName: 'Category name',
  categoryDescription: 'Category description',
  createCategory: 'Create category',
  categoryDeleted: 'Categoría eliminada con éxito',
  noDescription: 'Sin descripción',
  enterNumber: 'Ingresa un número',
  greaterThanZero: 'El ingreso debe ser mayor o igual que cero',
  maxCharacters: 'El máximo de caracteres es 35',
  requiredValue: 'Este valor es requerido',
  validDate: 'Debes ingresar una fecha válida',
  createIncome: 'Crear Ingreso',
  card: 'Tarjeta',
  transfer: 'Transferencia',
  onCredit: 'Por cobrar',
  incomeCreated: 'Ingreso creado con éxito',
  chargev2: 'Cobro',
  createExpense: 'Crear Gasto',
  realized: 'Realizado',
  productNotRegistered: 'No tienes registrado este producto.',
  lastScan: 'Último Escaneo',
  firstOfMonth: 'Primer día del mes',
  lastOfMonth: 'Final del mes',
  middleOfMonth: 'Mitad del mes',
  each: 'Cada',
  ofTheMonth: 'del mes',
  chooseProduct: 'Escoge el producto que deseas',
  sell: 'vender',
  searchSomething: '¿Deseas buscar algo?',
  productOutOfStock: 'Producto sin suficiente stock',
  notEnoughStock: 'No cuentas con suficiente stock para esta venta.',
  ok: 'Ok',
  inThisSale: 'En esta venta:',
  choosetheserviceyouwant: 'Escoge el servicio que deseas',
  validNumber: 'Ingresa un número válido',
  greaterOrEqualOne: 'Debe ser mayor o igual que 1',
  requiredField: 'Campo requerido',
  dateRequired: 'Fecha es requerido',
  expenseCreated: 'Gasto creado con éxito',
  inventoryDisabled: 'Inventario deshabilitado',
  inThisExpense: 'En este gasto',
  purchaseProduct: 'Compra de producto',
  cashPayment: 'Efectivo',
  cardPayment: 'Tarjeta',
  bankTransfer: 'Transferencia',
  payable: 'Por pagar',
  recurrentExpense: 'Gasto recurrente',
  months: 'Meses',
  period: 'Período',
  select: 'Seleccionar',
  category: 'Categoría',
  for: 'por',
  recurringAmountNotice: 'Este monto se registrará cada',
  paymentReminderNotice: 'Te enviaremos recordatorios de cobro cada',
  noExpenseRegistered: 'Este gasto no se registrará.',
  saleEditedSuccessfully: 'Venta editada con éxito',
  enterValidNumber: 'Ingresa un número válido',
  additionalSale: 'Venta adicional',
  comments: 'Comentarios',
  editSale: 'Editar venta',
  optional: 'Opcional',
  recurringSale: 'Esta venta es recurrente.',
  editQuote: 'Editar cotización',
  thisSaleIsRecurrent: 'Esta venta es recurrente.',
  quoteEditedSuccessfully: 'Cotización editada con éxito',
  maxCharacters140: 'El máximo de caracteres es 140',
  requiredDate: 'Fecha es requerido',
  enterValidDate: 'Ingresa una fecha válida',
  inThisQuote: 'En esta cotización',
  recurrentSale: 'Venta recurrente',
  saleCreatedSuccessfully: 'Venta creada con éxito',
  quoteCreatedSuccessfully: 'Cotización creada con éxito',
  daysOfValidity: 'Días de vigencia',
  toReceive: 'Por recibir',
  thissalewillnotberegistered: 'Esta venta no se registrará.',
  createSale: 'Crear venta',
  expand: 'Expandir',
  collapse: 'Colapsar',
  stock: 'Stock',
  searchServices: 'Buscar servicios',
  highestPrice: 'Mayor precio',
  lowestPrice: 'Menor precio',
  aToZ: 'A - Z',
  zToA: 'Z - A',
  mostRecent: 'Más Reciente',
  oldest: 'Más Antiguo',
  allServices: 'Todos los servicios',
  discountApplied:
    'El descuento se aplicará a TODOS los servicios de tu inventario, incluidos los no visibles en la lista.',
  serviceDeleted: 'Servicio eliminado con éxito',
  priceRequired: 'Precio es requerido',
  maxImages: 'La cantidad máxima de imágenes es 5',
  serviceName: 'Nombre del servicio',
  serviceDescription: 'Descripción del servicio',
  noCategory: 'Sin categoría',
  createNewCategory: 'Crear nueva categoría',
  showInStore: 'Mostrar en tienda en línea',
  showInPOS: 'Mostrar en PDV',
  configureAddons: 'Configurar Adicionales',
  salePrice: 'Precio de venta',
  createService: 'Crear Servicio',
  fixErrors: 'Por favor corrige los errores he inténtalo de nuevo.',
  serviceUpdated: 'Servicio actualizado con éxito',
  serviceCreated: 'Servicio creado con éxito',
  addCategory: 'Agregar categoría',
  categoryCreatedSuccessfully: 'Categoría creada con éxito',
  categoryDeletedSuccessfully: 'Categoría eliminada con éxito',
  users: 'Usuarios',
  invitations: 'Invitaciones',
  usersCount: 'Usuarios',
  ariaLabelUsers: 'usuarios',
  ariaLabelInvitations: 'invitaciones',
  usersAndInvitations: 'usuarios e invitaciones',
  invitationAccepted: 'Invitación aceptada',
  invitationPending: 'Invitación pendiente',
  noInvitesYet: 'Aún no invitas a nadie al rol',
  inviteUsers: 'Invitar usuarios',
  irreversibleAction: 'Acción irreversible',
  deleteInvitation: '¿Deseas eliminar la invitación',
  invitationNumber: 'Invitación #',
  invitationsSentSuccessfully: 'Invitaciones enviadas con éxito.',
  addAnother: 'Agregar otra',
  sending: 'Enviando...',
  sendInvitations: 'Enviar invitaciones',
  roleNameRequired: 'Nombre del rol es requerido',
  roleUpdatedSuccessfully: 'Rol actualizado con éxito',
  updatingRole: 'Actualizando rol',
  creatingRole: 'Creando rol',
  updateRole: 'Actualizar rol',
  createRole: 'Crear rol',
  pointOfSale: 'Punto de venta',
  serviceCategories: 'Categorías de servicios',
  details: 'Detalles',
  store: 'Tienda',
  allowAll: 'Permitir todo',
  serviceCatalog: 'Catálogo de servicios',
  createServices: 'Crear servicios',
  deleteServices: 'Eliminar servicios',
  editServices: 'Editar servicios',
  seeServiceCategories: 'Ver categorías de servicio',
  createServiceCategories: 'Crear categorías de servicio',
  updateServiceCategories: 'Actualizar categorías de servicio',
  deleteServiceCategories: 'Eliminar categorías de servicio',
  editOrders: 'Editar órdenes',
  deleteOrders: 'Eliminar órdenes',
  seeAnalytics: 'Ver analíticas',
  productCatalog: 'Catálogo de productos',
  seeProductCosts: 'Ver costo de los productos',
  createProducts: 'Crear productos',
  deleteProducts: 'Eliminar productos',
  bulkDeleteProducts: 'Eliminar productos en masa',
  editProducts: 'Editar productos',
  seeStockAnalytics: 'Ver analíticas',
  seeModifiers: 'Ver modificadores',
  seeFinancialStatements: 'Ver EEFFs',
  seeCategories: 'Ver categorías',
  createCategories: 'Crear categorías',
  updateCategories: 'Actualizar categorías',
  bulkAssignCategories: 'Asignar categorías en masa',
  deleteCategories: 'Eliminar categorías',
  budgetManagement: 'Administración del presupuesto',
  seeDetailsAnalytics: 'Ver analíticas',
  deleteTransactions: 'Eliminar transacciones',
  massiveImport: 'Importación masiva',
  manageOffers: 'Administración de campañas y ofertas',
  configureStore: 'Configuración de tienda',
  deleteRoleMessage: '¿Deseas eliminar el rol',
  deleteRole: 'Eliminar rol',
  saleGreaterThanZero: 'La venta debe ser mayor o igual que cero',
  maxCharacters35: 'El máximo de caracteres es 35',
  depositRegisteredSuccessfully: 'Abono registrado con éxito',
  bank: 'Transferencia',
  quoteExpired: 'Cotización expirada',
  generateSale: 'Generar venta',
  incomes: 'Ingresos',
  savings: 'Ahorro',
  topExpenseCategories: 'Top categorías de gastos',
  quoteDeletedSuccessfully: 'Cotización eliminada con éxito',
  deleteQuoteConfirmation: '¿Eliminar Cotización',
  pleaseConfirmToProceed: 'Por favor confirma para continuar.',
  searchQuoteById: 'Buscar cotización por ID',
  profit: 'Utilidad',
  confirmationRequired: 'Confirmación requerida',
  deleteTransactionMessage:
    'La transacción que estás a punto de eliminar afectará los gastos del negocio y el inventario de los productos en dicha operación.',
  allFilter: 'Todas',
  cashFilter: 'Efectivo',
  cardFilter: 'Tarjeta',
  transferFilter: 'Tranferencia',
  creditFilter: 'Crédito',
  creditPayment: 'Crédito',
  saleLabel: 'Venta',
  incomeLabel: 'Ingreso',
  posLabel: 'PDV',
  purchaseLabel: 'Compra',
  expenseLabel: 'Gasto',
  depositLabel: 'Abono',
  deleteTransactionTitle: '¿Eliminar transacción',
  deleteConfirmMessage: 'Por favor confirma para continuar',
  cancelButton: 'Cancelar',
  deleteButton: 'Eliminar',
  searchCodeLabel: 'Buscar por código',
  searchCodePlaceholder: 'Ej. UAZCXWSZ',
  paymentMethodsLabel: 'Formas de pago',
  typeColumn: 'Tipo',
  idColumn: 'ID',
  descriptionColumn: 'Descripción',
  paymentTypeColumn: 'Tipo de pago',
  contactColumn: 'Contacto',
  amountColumn: 'Monto',
  dateColumn: 'Fecha',
  actionsColumn: 'Acciones',
  showMoreButton: 'Ver más',
  filtersApplied: 'Filtros aplicados',
  transactionDeleted: 'Transacción eliminada',
  recurringTransactionTooltip: 'Transacción recurrente',
  cashRegisterCut: 'Corte de caja',
  selectTimeRange:
    'Por favor selecciona un rango de tiempo para generar el reporte:',
  daily: 'Diario',
  custom: 'Personalizado',
  error: 'Ocurrió un error, por favor inténtalo más tarde.',
  downloadCashRegisterCut: 'Descargar',
  loadingCashRegisterCut: 'Cargando',
  cashRegisterCutSuccess: 'Corte generado con éxito',
  cashRegisterCutError: 'Ocurrió un error',
  cashRegisterCutFileName: 'corte_de_caja',
  cashType: 'Al contado',
  creditType: 'Al crédito',
  dateCut: 'Corte',
  cashSalesCashCut: 'Ventas al contado',
  transferPayment: 'Transferencias',
  cardPaymentCashCut: 'Tarjetas',
  webOrders: 'Órdenes web',
  totalLabel: 'Total',
  creditSalesCashCut: 'Ventas al crédito',
  totalSalesCashCut: 'Total ventas',
  cashCharges: 'Cargos efectivo',
  transferCharges: 'Cargos por transferencias',
  cardCharges: 'Cargos por tarjeta',
  totalCashGenerated: 'Total efectivo generado',
  pendingToLiquidate: 'Pendiente de liquidar',
  cashExpensesCashCut: 'Gastos/compras al contado',
  creditExpensesCashCut: 'Gastos/compras al crédito',
  totalExpenses: 'Total gastos/compras',
  cashDeposits: 'Abonos efectivo',
  transferDeposits: 'Abonos por transferencias',
  cardDeposits: 'Abonos por tarjetas',
  totalCashOutcome: 'Total salida efectivo',
  cashRegisterBalance: 'SALDO EFECTIVO CAJA',
  productHistory: 'Historial de productos comprados',
  transactionHistory: 'Historial de transacciones',
  editContact: 'Editar contacto',
  deleteContact: 'Eliminar contacto',
  contactDeleted: 'Contacto eliminado con éxito',
  productsLabel: 'Productos',
  transactionsLabel: 'Transacciones',
  nameLabel: 'Nombre',
  phoneLabel: 'Teléfono',
  emailLabel: 'Correo electrónico',
  addressLabel: 'Dirección',
  contactModified: 'Contacto modificado con éxito',
  contactCreated: 'Contacto creado con éxito',
  successfully: 'con éxito',
  salesToReceive: 'Ingresos por recibir',
  expensesToPay: 'Gastos por pagar',
  productDeletedSuccessfully: 'Producto eliminado con éxito',
  inStock: 'EN STOCK',
  unifyWebAndPOS: 'Unificar ventas Web y PDV',
  unifyWebAndPOSDescription:
    'Esta opción te permitirá visualizar tus órdenes web y de Punto de Venta en un solo lugar (el apartado Órdenes de PDV)',
  unifyTransactions: 'Unificar transacciones',
  salesReceipts: 'Recibos de venta',
  addLogoToReceipts:
    '1. Agrega el logo de tu negocio a tus recibos (tickets) de venta.',
  hideLogoOnReceipts: 'Ocultar logo en recibos',
  freeTextFields:
    '2. Campos de texto libre. Utiliza estos campos para agregar información extra en tus recibos.',
  line1: 'Línea 1',
  line2: 'Línea 2',
  line3: 'Línea 3',
  line4: 'Línea 4',
  addCustomMessageToReceipts:
    '3. Agrega un mensaje personalizado a tus recibos (tickets) de venta.',
  receiptMessage: 'Mensaje en recibo',
  imageFormats: '*.jpeg, *.jpg, *.png, *.gif\n250x250 max.',
  onlineStore: 'Tienda en línea',
  addBusinessColors:
    'Agrega los colores de tu negocio y personaliza la URL de tu tienda.',
  customizeURL: 'Personaliza tu URL',
  primaryColor: 'Color principal',
  buttonExample: 'Ejemplo de botón',
  invalidColor: 'Color inválido',
  secondaryColor: 'Color secundario',
  lowStockWarning: 'Advertencia de bajo stock',
  lowStockWarningDescription:
    'Utiliza este campo para configurar la cantidad mínima de stock en tu inventario, si el stock de tus productos baja de esta cantidad te mostraremos una advertencia de bajo stock.',
  personalizationUpdatedSuccessfully: 'Personalización actualizada con éxito',
  newModifier: 'Nuevo Adicional',
  color: 'Color',
  service: 'Servicio',
  create: 'Crear',
  deletedSuccessfully: 'Eliminado con éxito',
  editModifier: 'Editar',
  deleteModifier: 'Eliminar',
  whatsTheDifference: '¿Cuál es la diferencia?',
  couponsDescription:
    'Puedes crear códigos alfanuméricos para que tus clientes puedan ingresarlo al momento de su compra y se les aplique una reducción % porcentual o $ en dinero a su monto Total.',
  note: 'Nota',
  noteDescription: 'Aplicará para todos los productos de tu catálogo.',
  discountsDescription:
    'Puedes aplicar descuentos en % porcentajes o $ dinero a uno o varios productos de tu catálogo',
  couponActivatedSuccessfully: 'CUPÓN activado con éxito',
  couponDeactivatedSuccessfully: 'CUPÓN desactivado con éxito',
  discountActivatedSuccessfully: 'DESCUENTO activado con éxito',
  discountDeactivatedSuccessfully: 'DESCUENTO desactivado con éxito',
  confirmAction: 'Confirmar acción',
  activateCoupon: 'activar el cupón',
  deactivateCoupon: 'desactivar el cupón',
  activateDiscount: 'activar el descuento',
  deactivateDiscount: 'desactivar el descuento',
  yesActivate: 'Sí, activar',
  yesDeactivate: 'Sí, desactivar',
  activate: 'Activar',
  deactivate: 'Desactivar',
  whish: '¿Deseas',
  the: 'el',
  percentage: 'Porcentaje',
  money: 'Dinero',
  applyValueMessage: 'Este valor se aplicará a',
  selectedProducts: 'a los productos que escojas de tu catálogo',
  activeCampaigns: 'Campañas activas',
  startDateTime: 'Fecha y hora de inicio',
  endDateTime: 'Fecha y hora de final',
  value: 'Valor',
  unit: 'Unidad',
  createdAt: 'Fecha de creación',
  expiresAt: 'Expira',
  active: 'Activo',
  inactive: 'Inactivo',
  maxActivations: 'Max. Activaciones',
  percentageWithSymbol: 'Porcentaje (%)',
  before: 'Antes',
  now: 'Ahora',
  discountCreated: 'Descuento creado con éxito',
  couponValueRequired: 'Debes especificar el valor del cupón',
  startDateRequired: 'Debes seleccionar fecha de inicio',
  endDateRequired: 'Debes seleccionar fecha de expiración',
  selectProducts:
    "Debes seleccionar al menos 1 producto o marcar 'Todos los productos'.",
  discountLabel: '¿Cómo deseas que funcione tu descuento?',
  discountDurationLabel:
    'Define cuánto tiempo quieres que esté activo el descuento',
  productsInDiscount: 'Productos en este descuento',
  chooseProducts:
    'Escoge los productos a los que le desees activar el descuento',
  createDiscount: 'Crear descuento',
  applyDiscount: 'Se aplicará',
  toOriginalPrices: 'a los precios originales de los productos seleccionados',
  validFrom: 'Estará vigente del',
  to: 'al',
  allProducts: 'todos los productos del inventario',
  immediateApplication:
    'Al crearlo se aplicará de forma inmediata y tus clientes podrán verlos.',
  onlineStoreMessage:
    'Se publicará una barra con mensaje en tu tienda en línea.',
  willBeApplied: 'Se aplicará',
  expiration: '(expiración)',
  discountWillApply: 'Se aplicará el descuento a',
  thev2: 'los',
  selected: 'seleccionados',
  publishDiscounts: 'Sí, deseo publicar los descuentos',
  barColor: 'Color de barra',
  writeText: 'Escribe el texto',
  preview: 'Previsualización',
  couponCreated: 'Cupón creado con éxito',
  couponNameRequired: 'Debes especificar el nombre del cupón',
  couponCodeRequired: 'Debes especificar el código del cupón',
  couponCodeMaxLength: 'Debe ser 15 caracteres máximo',
  maxActivationsDescription:
    'Limita la cantidad de veces que este cupón podrá canjearse.',
  shareCodeWarning: 'Debes compartir el código.',
  shareCodeDescription:
    'El código no se publicará en ninguna parte de tu tienda en línea, puedes compartirlo en redes sociales, o como prefieras.',
  createNewCoupon: 'Crear nuevo cupón',
  createCoupon: 'Crear cupón',
  toTotalAmount: 'al monto total al momento que realicen un pedido.',
  chooseActiveCode:
    'Puedes escoger en Campañas activas qué código deseas tener activo',
  onlyOneCode: 'Solo puedes activar un código a la vez',
  exampleCode: 'Ejemplo: PROM0001',
  instruction:
    'Tus clientes deberán digitar este código al momento de hacer su pedido en tu tienda en línea',
  discountFunctionality: '¿Cómo deseas que funcione tu descuento?',
  totalPurchase: 'al total de la compra',
  defineDuration: 'Define cuánto tiempo quieres que esté activo el descuento',
  limitUsage: 'Limita la cantidad de veces que este cupón podrá canjearse.',
  createCode: 'Crea tu código',
  validUntil: 'al monto total al momento que realicen un pedido.',
  searchOrders: 'Buscar órdenes',
  posOrders: 'Punto de venta',
  orderRejected: 'Orden web rechazada con éxito',
  confirmReject: '¿Estás seguro que deseas rechazarla?',
  notPaid: 'No Pagada',
  delivered: 'Entregada',
  complete: 'Completar',
  orderDeleted: 'Orden eliminada con éxito',
  editOrder: 'Editar orden',
  webOrder: 'Orden web',
  pos: 'PDV',
  homeDelivery: 'Entregar a domicilio',
  table: 'Mesa',
  comment: 'Comentario',
  detail: 'Detalle',
  delivery: 'Envío',
  createOrder: 'Crear orden',
  saleUpdated: 'Venta actualizada con éxito',
  saleCreated: 'Venta creada con éxito',
  confirmCancelOrder: 'Confirmar cancelar orden',
  allValuesWillBeDeleted: 'Todos los valores serán borrados.',
  cancelOrder: 'Cancelar orden',
  goToPayment: 'Ir al pago',
  updateOrder: 'Actualizar orden',
  generatePayment: 'Generar pago',
  deliveryAmount: 'Costo de envío',
  clientName: 'Nombre de cliente',
  otherNonOperatingIncome: 'Otros ingresos no operativos',
  customize: 'Personalizar',
  totalPrice: 'Precio total',
  selectPaymentMethod: 'Selecciona forma de pago',
  itemInOrder: 'item en la orden',
  subtotal: 'Subtotal',
  totalToPay: 'Total a pagar',
  itemsInOrder: 'items en la orden',
  saleWithoutProductsOrServices: 'Esta venta no incluye productos o servicios.',
  enterAmount: 'Digite cantidad',
  changeToClient: 'Cambio a cliente',
  grossSales: 'Venta bruta',
  netSales: 'Venta neta',
  totalOrders: 'Órdenes generadas',
  avgTicket: 'Tickets promedio',
  cashSalesv2: 'Ventas en efectivo',
  cardSales: 'Ventas con tarjeta',
  bankSales: 'Ventas con transferencia',
  topSellingProducts: 'Productos más vendidos',
  topSellingServices: 'Servicios más vendidos',
  removeProperty: 'Quitar propiedad',
  addProperty: 'Agregar propiedad',
  size: 'Tamaño / Talla',
  style: 'Estilo / Material',
  imageLimit: 'Límite de 5 imágenes por producto',
  barcodeInUse: 'Código de barras ya está en uso',
  searchProducts: 'Buscar productos',
  discountWarning:
    'El descuento se aplicará a TODOS los productos de tu inventario, incluidos los no visibles en la lista.',
  searchBy: 'Buscar por',
  costPercentage: '% Costo',
  grossMargin: 'Margen bruto',
  unsoldProducts: 'Productos sin venta',
  bestSellers: 'Más vendidos',
  worstSellers: 'Menos vendidos',
  inCollection: 'En colección',
  viewProducts: 'Ver productos',
  averageMonthlySales: 'Ventas promedio mes (últ. año)',
  averageMonthlyProductSales: 'Productos vendidos promedio mes (últ. año)',
  averageMonthlyProductCost: 'Costos de productos promedio mes (últ. año)',
  averageMonthlyExpenses: 'Gastos promedio mes (últ. año)',
  averageMonthlyProfits: 'Ganancias promedio mes (últ. año)',
  finalSalesPlan: 'Ventas plan final',
  planCosts: 'Costos plan final',
  operativeExpenses: 'Gastos operativos',
  finalUtilityRevenue: 'Utilidad / Ganancia',
  lastYearSales: 'Ventas año anterior',
  salesProyectionType: 'Método de proyección',
  incremental: 'Incremental',
  zero_base: 'Base cero',
  infoZeroBase: 'Tu plan NO tomará en cuenta información histórica',
  infoIncremental: 'Tu plan se hará CON base la información histórica',
  salesGrowthType: 'Tipo de crecimiento',
  growth: 'Crecimiento',
  salesGrowthAmount: 'Digitar monto',
  salesPlan: 'Ventas plan',
  finalPlan: 'Plan final',
  adjustmentsPlan: 'Ajustes plan',
  summary: 'Resumen',
  lastYearExpenses: 'Gastos año anterior',
  growthProyectionType: 'Método de proyección',
  expensesGrowthType: 'Tipo de crecimiento',
  expensesGrowthAmount: 'Digitar monto',
  expensesPlan: 'Gastos plan',
  finalExpenses: 'Gastos final',
  profitAverage: 'En promedio, tus productos te dejan de utilidad/ganancia',
  profitSummary: 'En promedio, tus productos te dejan',
  grossProfit: 'Utilidad bruta',
  finalPlanCosts: 'Costos plan final',
  salesCost: 'Sales cost',
  bruteMargin: 'Brute margin',
  ebitda: 'Operational margin (EBITDA)',
  otherIncomes: 'Other incomes',
  otherExpenses: 'Other expenses',
  netMargin: 'Net margin',
  mainIndicators: 'Main indicators of the Income Statement',
  percentageWeight: 'Percentage weight relative to income',
  totalSalesEFFF: '(+) Ventas totales (crédito & contado)',
  salesCostEFFF: '(-) Costo de ventas',
  discountsEFFF: '(-) Descuentos totales',
  bruteMarginEFFF: '(=) Utilidad bruta',
  operativeExpensesEFFF: '(-) Gastos operativos',
  ebitdaEFFF: '(=) Utilidad operacional (EBITDA)',
  otherIncomesEFFF: '(+) Otros ingresos',
  otherExpensesEFFF: '(-) Otros Gastos',
  accUtilityEFFF: '(+/-) Utilidad contable',
  incomeStatementEFFF: 'Estado de Resultados',
  accUtility: '(+/-) Utilidad contable',
  atc: '(+/-) Cambio neto CxC',
  atp: '(+/-) Cambio neto CxP',
  inventoryEFFF: '(+/-) Inventario',
  operativeFlow: '(+/-) Flujo de operaciones',
  furnitureAndEquipment: '(+/-) Compra de mobiliario y equipo',
  investmentFlow: '(+/-) Flujo de inversiones',
  ownResources: '(+/-) Recursos Propios',
  financingFlow: '(+/-) Flujo de financiamiento',
  initialBox: 'Efectivo al inicio del período',
  netPeriodFlow: '(+/-) Flujo efectivo neto',
  cashFlow: 'Flujo de efectivo',
  daysAccountsReceivable: 'Días cuentas por cobrar (CxC)',
  inventoryDays: 'Días inventario',
  daysAccountsPayable: 'Días cuentas por cobrar (CxP)',
  cashCycle: 'Ciclo de caja (CxC+Inv-CxP)',
  returnOnEquity: 'Retorno sobre el patrimonio (ROE)',
  returnOnAssets: 'Retorno sobre los activos (ROA)',
  mainBalanceSheetIndicators: 'Principales indicadores del Balance General',
  workingCapitalManagement: 'Gestión del capital de trabajo',
  turnovers: 'Rotaciones',
  profitability: 'Rentabilidad',
  accountsReceivable: 'Cuentas por cobrar',
  inventories: 'Inventarios',
  totalAssets: 'Total activos',
  accountsPayable: 'Cuentas por pagar',
  totalLiabilities: 'Total pasivos',
  ownResourcesEquity: 'Recursos propios (patrimonio)',
  currentEarnings: 'Utilidad del ejercicio',
  accumulatedAccountingProfit: 'Utilidad contable acumulada',
  totalEquity: 'Total patrimonio',
  balanceSheet: 'Balance General',
  assets: 'Activos',
  liabilitiesAndEquity: 'Pasivos + patrimonio',
  cashReflectedInOwnResources:
    'Este efectivo también estará reflejado en la cuenta Recursos Propios en Patrimonio',
  newEntry: 'Nuevo ingreso',
  enterBalanceAmounts: 'Ingresar saldos de balance',
  outOfStockProductsAtEnd:
    'Tus productos agotados se mostrarán al final del listado.',
  paymentProcessorNotice:
    'Cabal no es un procesador de pagos. El link que debes colocar, debe ser del proveedor que hayas contratado para hacer cobros digitales a tus clientes',
  pleaseWriteDescription: 'Por favor escribe una descripción de tu negocio',
  requiredFieldsMissing:
    'Hay campos requeridos sin completar, por favor revisa el formulario he inténtalo de nuevo:',
  howWillYouDispatchOrders: '1. ¿Cómo vas a despachar tus pedidos?',
  whatPaymentOptionsWillYouGiveToYourCustomers:
    '2. ¿Qué opciones de pago le darás a tu clientes?',
  letYourCustomersKnowYourSchedule:
    '3. Hazle saber tus horarios a tus clientes',
  whatDescriptionDoYouWantYourCustomersToSee:
    '4. ¿Qué descripción quieres que vean tu clientes?',
  doYouWantToAddYourBusinessSocialMedia:
    '5. ¿Quieres agregar las redes sociales de tu negocio?',
  paymentLink: '6. Enlace de pagos',
  showOutOfStockProductsAtTheEnd: '7. Mostrar productos sin stock al final',
  yourStoreBanner: '8. Banner de tu tienda',
  currentBanner: 'Banner actual:',
  costOfShipping: 'Costo de envío',
  rememberShippingCostInfo:
    'Recuerda que el costo de envío te encargas tú. Se informará a tus clientes que el costo puede variar según zona.',
  pickupInStore: 'Recoger en tienda',
  deliverToHome: 'Envíar a domicilio',
  uponDelivery: 'Al momento de entrega',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'Twitter',
  whatsapp: 'WhatsApp',
  yourUsername: 'tu-usuario',
  yourFacebookUrl: 'https://facebook.com/tu-url',
  enterYourWhatsappNumberInfo:
    'Por favor ingresa tu código de ciudad (si aplica) y tu número de WhatsApp sin guiones y sin espacios.',
  shippingCostReminder:
    'Recuerda que el costo de envío te encargas tú. Se informará a tus clientes que el costo puede variar según zona.',
  usernamePlaceholder: 'tu-usuario',
  cityCodeLabel: 'código de ciudad',
  enterYourLabel: 'Por favor ingresa tu',
  optionalWhatsAppLabel: '(si aplica) y tu número de WhatsApp',
  noDashesNoSpaces: 'sin guiones y sin espacios',
  addressRequired: 'Tu dirección es requerida',
  bankNameRequired: 'Tu banco es requerido',
  bankNumberRequired: 'Tu número de cuenta es requerido',
  confirmChanges: 'Confirmar cambios',
  reviewMessage:
    'Tu información pasará a revisión, mientras tanto tu tienda en línea no podrá procesar pagos con tarjeta. ¿Deseas continuar?',
  noCancel: 'No, cancelar',
  yesConfirm: 'Sí, confirmar',
  requestAccepted: 'Tu solicitud ha sido aceptada.',
  requestRejected: 'Tu solicitud ha sido rechazada.',
  requestInProgress: 'Tu información está en proceso de aprobación.',
  adminMessage: 'Tu información no es correcta.',
  bankName: 'Nombre del banco donde recibirás tus ventas:',
  bankSelection: 'Selecciona tu banco',
  accountReminder:
    'Recuerda que el nombre de la cuenta debe coincidir con el nombre asociado a la cuenta Cabal.',
  accountNumber: 'Número de cuenta:',
  uploadFrontId: 'Sube una foto del frente de tu DUI.',
  uploadBackId: 'Sube una foto del revés de tu DUI.',
  uploadFacePhoto: 'Sube una foto de tu rostro',
  current: 'Actual:',
  editOrganization: 'Editar organización',
  createOrganization: 'Crear organización',
  businessNameRequired: 'El nombre de tu negocio es requerido',
  businessTypeRequired: 'Tipo de negocio es requerido',
  industryRequired: 'Por favor selecciona tu rubro',
  countryRequired: 'País es requerido',
  businessName: 'Nombre de negocio',
  country: 'País',
  selectCountry: 'Selecciona tu país',
  currencyFormat: 'Formato de moneda',
  howToUseCabal: '¿Cómo deseas utilizar Cabal?',
  businessFinance: 'Finanzas empresariales',
  industry: 'Rubro',
  selectIndustry: 'Selecciona tu rubro',
  successUpdatedOrg: 'Organización actualizada con éxito',
  successCreateddOrg: 'Organización creada con éxito',
  owner: 'Dueño',
  guest: 'Invitado',
  deleteOrganizationMessage: 'Se eliminará la organización',
  deleteOrganizationButton: 'Eliminar organización',
  uploadCompleted: 'Carga completada',
  uploadFailed: 'Carga fallida',
  processingInformation: 'Procesando información',
  pleaseselectafile: 'Por favor selecciona un archivo',
  uploadSuccess: 'Data recibida con éxito.',
  startUpload: 'Iniciar Carga',
  downloadTemplate: 'Descargar plantilla',
  selectOrDragFile: 'Seleccione o arrastre archivo aquí',
  fileType: 'Microsoft Excel (.xlsx)',
  assignProductsTo: 'Asignar productos a',
  assignSelection: 'Asignar selección',
  moveToInProgress: 'Mover a en progreso',
  finalize: 'Finalizar',
  finalized: 'Finalizadas',
  other: 'Otro',
  ofthemonth: 'del mes',
  firstDayOfMonth: 'Primer día del mes',
  endOfMonth: 'Final del mes',
  includeOffers: 'Incluir ofertas',
  hideOutOfStock: 'Ocultar productos sin stock',
  insufficientStock: 'No cuentas con suficiente stock para esta venta.',
  priceTooltip:
    'Utiliza este campo para vender este producto a un precio diferente. El precio en inventario no se modificará.',
  allUsers: 'Todos los usuarios',
  expenseEditedSuccessfully: 'Gasto editado con éxito',
  mustBeGreaterThanOrEqualToOne: 'Debe ser mayor o igual que 1',
  valueRequired: 'Este valor es requerido',
  productPurchase: 'Compra de producto',
  thisExpenseIsRecurrent: 'Este gasto es recurrente.',
  editExpense: 'Editar gasto',
  rolesAndPermissions: 'Roles y permisos para tus empleados',
  offerCampaigns: 'Campañas de ofertas para la tienda en línea',
  multipleBusinesses: 'Múltiples negocios en una sola cuenta',
  profitReports: 'Reportes de ganancias y efectivo',
  confirmationEmail: 'Recibe correo de confirmación de',
  subscriptionCanceled: 'Tu suscripción está cancelada.',
  choosePlan: 'Luego podrás ser Plus eligiendo el plan que mejor te convenga.',
  unlockPotential: 'Desbloquea tu máximo potencial con:',
  startingIsEasy: 'Comenzar es muy fácil:',
  startFreeTrialCTA: "Dale click al botón de 'Iniciar prueba gratuita'.",
  receiveConfirmationEmail: 'Recibe correo de confirmación de',
  trialPeriodReminder: 'Cuando tu prueba de',
  trialDays: '7 DÍAS',
  trialEndingReminder:
    'esté por vencer recibirás un recordatorio, y podrás completar tu pago dentro de tu usuario.',
  cancelAnytimeCTA:
    'Cancela en cualquier momento que desees, sin explicaciones ni complicaciones.',
  unlockYourFullPotential: 'Desbloquea tu máximo potencial',
  selectYourPlan: 'Selecciona tu plan',
  monthlyCTA: '/month',
  annual: '/year',
  threeMonths: '/month for 3 months',
  twentyPercentOff: '20% OFF',
  fiftySevenPercentOff: '57% OFF',
  normalPrice: 'After 3 months, normal price USD$6.99',
  cancelAnytimeCTA2: "Cancela cuando quieras",
  monthlyv2: 'Mensual',
  annualv2: 'Anual',
};
export type GeneralStrings = typeof general;
