import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ReactElement, cloneElement, useState } from 'react';

import { useAnalytics } from 'src/hooks/useAnalytics';
import { usePermissions } from 'src/hooks/usePermissions';

import { JoinCabalPlus } from './JoinCabalPlus';
import { hasOffersEnabled } from './JoinCabalPlus/data/subscriptionTypes';
import { useStrings } from 'src/hooks/useStrings';

type Props = {
  label?: string;
  children?: ReactElement;
  buttonProps?: React.ComponentProps<typeof Button>;
  customRender?: ReactElement;
  isDirectBuy?: boolean;
};

export const CabalPlusManualModal = ({
  children,
  label = '',
  buttonProps,
  customRender,
  isDirectBuy,
}: Props) => {
  const { strings } = useStrings();
  const [open, setOpen] = useState(false);
  const { has } = usePermissions();
  const { sendAnalyticsEvent } = useAnalytics();

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    sendAnalyticsEvent('user_closed_cabal_plus_modal');
    setOpen(false);
    localStorage.setItem('joinCabalPlusModal', 'closed');
  };
  const enableOffers = hasOffersEnabled();

  return !has('paid_access') ? (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll="body"
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 800,
          },
        }}
      >
        <DialogContent>
          <JoinCabalPlus
            isDirectBuy={isDirectBuy}
            enableOffers={enableOffers}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{strings('close')}</Button>
        </DialogActions>
      </Dialog>
      {customRender ? (
        cloneElement(customRender, {
          onClick: handleOpenModal,
        })
      ) : (
        <Button variant="contained" onClick={handleOpenModal} {...buttonProps}>
          {label}
        </Button>
      )}
    </>
  ) : (
    <>{children}</>
  );
};
