import {
  IconDefinition,
  faBuilding,
  faCheck,
  faEllipsisVertical,
  faFileInvoiceDollar,
  faMoneyBillTrendUp,
  faTag,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Check } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { m } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { useAnalytics } from 'src/hooks/useAnalytics';
import useAuth from 'src/hooks/useAuth';
import { usePermissions } from 'src/hooks/usePermissions';
import { useStrings } from 'src/hooks/useStrings';
import { useSubscription } from 'src/hooks/useSubscription';
import palette from 'src/theme/palette';

import { List } from '../../List';
import { MotionContainer, varBounce } from '../../animate';
import { TrialElement } from '../TrialElement';
import { SubscriptionOptions } from './SubscriptionOptions';
import { defaultPlans, offerPlans } from './data/subscriptionTypes';

type Props = {
  onCancel?: () => void;
  isDirectBuy?: boolean;
  enableOffers: boolean;
};

type BenefitListItemProps = {
  description: ReactNode;
  icon: IconDefinition;
  alt?: boolean;
};

const benefitsList: BenefitListItemProps[] = [
  {
    description: 'Roles y permisos para tus empleados',
    icon: faUsers,
  },
  {
    description: 'Plan de ventas para las finanzas',
    icon: faMoneyBillTrendUp,
  },
  {
    description: 'Campañas de ofertas para la tienda en línea',
    icon: faTag,
  },
  {
    description: 'Múltiples negocios en una sola cuenta',
    icon: faBuilding,
  },
  {
    description: 'Reportes de ganancias y efectivo',
    icon: faFileInvoiceDollar,
  },
];

const howToStartList: BenefitListItemProps[] = [
  {
    description: 'Dale click al botón de "Iniciar prueba gratuita".',
    icon: faCheck,
  },
  {
    description: (
      <Typography fontSize={17} paragraph marginBottom={0}>
        Recibe correo de confirmación de{' '}
        <img src="/assets/Stripe.png" alt="Stripe" width={48} />
      </Typography>
    ),
    icon: faCheck,
  },
  {
    description: (
      <Typography fontSize={17} paragraph marginBottom={0}>
        Cuando tu prueba de <strong>7 DÍAS</strong> esté por vencer recibirás un
        recordatorio, y podrás completar tu pago dentro de tu usuario{' '}
        <img src="/assets/cabal_mini.png" alt="Cabal" width={68} />
      </Typography>
    ),
    icon: faCheck,
  },
  {
    description:
      'Cancela en cualquier momento que desees, sin explicaciones ni complicaciones.',
    icon: faCheck,
  },
];

const BenefitListItem: React.FC<BenefitListItemProps> = ({
  description,
  icon,
  alt,
}) => (
  <Stack spacing={2} direction="row" alignItems="flex-start">
    <div>
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          background: '#ccbfd7',
          width: 36,
          height: 36,
          borderRadius: 60,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          color="#552b7c"
          style={{ display: 'block' }}
        />
      </Stack>
    </div>

    {typeof description === 'string' ? (
      <Typography
        fontSize={17}
        paragraph
        marginBottom={0}
        style={{
          color: alt ? 'white' : '#212b36',
        }}
      >
        {description}
      </Typography>
    ) : (
      description
    )}
  </Stack>
);

const Message = ({ message }: { message: string }) => (
  <div style={{ maxWidth: 400, margin: '0 auto 30px' }}>
    <Alert severity="warning" style={{ marginBottom: 20 }}>
      {message}
    </Alert>
  </div>
);

export const JoinCabalPlus: React.FC<Props> = ({
  onCancel,
  isDirectBuy,
  enableOffers,
}) => {
  const { strings } = useStrings();
  const { sendAnalyticsEvent } = useAnalytics();
  const { user, updateUser: refreshUser } = useAuth();
  const { has } = usePermissions();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data: subscription, is } = useSubscription();

  benefitsList[0].description = strings('rolesAndPermissions');
  benefitsList[1].description = strings('salesPlan');
  benefitsList[2].description = strings('offerCampaigns');
  benefitsList[3].description = strings('multipleBusinesses');
  benefitsList[4].description = strings('profitReports');

  howToStartList[0].description = strings('startFreeTrial');
  howToStartList[1].description = (
    <Typography fontSize={17} paragraph marginBottom={0}>
      {strings('receiveConfirmationEmail')}{' '}
      <img src="/assets/Stripe.png" alt="Stripe" width={48} />
    </Typography>
  );
  howToStartList[2].description = (
    <Typography fontSize={17} paragraph marginBottom={0}>
      {strings('trialPeriodReminder')} <strong> {strings('trialDays')}</strong>{' '}
      {strings('trialEndingReminder')}{' '}
      <img src="/assets/cabal_mini.png" alt="Cabal" width={68} />
    </Typography>
  );
  howToStartList[3].description = strings('cancelAnytime');

  let showTrialCTA = subscription && !Boolean(subscription.data.provider);
  let status = subscription?.data.status;
  const trialExpired = parseInt(status as string) === 0;
  const subscriptionOptions =
    enableOffers && offerPlans.length > 0
      ? offerPlans(strings)
      : defaultPlans(strings);

  if (trialExpired || status === 'canceled') showTrialCTA = false;

  const handleTrialSuccess = () => {
    sendAnalyticsEvent('subscription_plus_free_trial');
    setShowConfirmation(true);
  };

  const handleContinue = () => {
    if (user) {
      setShowConfirmation(false);
      refreshUser({
        ...user,
        current_organization: {
          ...user.current_organization,
          has_paid_access: true,
        },
      });
    }
  };

  return subscription ? (
    <Container component={MotionContainer}>
      <m.div variants={varBounce().in}>
        <div style={{ maxWidth: 160, margin: '0 auto' }}>
          <img
            src="/assets/cabal_plus_badge1.png"
            alt="Cabal Plus"
            width="160"
            height="auto"
          />
        </div>

        {(status === 'expired' ||
          status === 'incomplete_expired' ||
          (status === 'active' && !has('paid_access'))) && (
          <Message message={strings('subscriptionExpired')} />
        )}

        {status === 'payment-failed' && (
          <Message message={strings('paymentFailed')} />
        )}

        {trialExpired && <Message message={strings('trialExpired')} />}

        {status === 'canceled' && (
          <Message message={strings('subscriptionCanceled')} />
        )}

        {showTrialCTA && !isDirectBuy && !enableOffers ? (
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5" marginTop={-3} marginBottom={1}>
              Comienza ahora la prueba gratuita de 7 DÍAS,
              <br />
              <span style={{ textDecoration: 'underline' }}>SIN</span> ingresar
              tarjeta de crédito/débito.
            </Typography>
            <Typography
              style={{ color: '#888', fontSize: 16 }}
              marginBottom={3}
            >
              {strings('choosePlan')}
            </Typography>

            <TrialElement onSuccess={handleTrialSuccess} />
          </div>
        ) : (
          <>
            {(status !== 'active' ||
              (status === 'active' && !has('paid_access')) ||
              (status === 'active' && is('trial_subscription'))) && (
              <SubscriptionOptions
                options={subscriptionOptions}
                isDirectBuy={isDirectBuy || enableOffers}
              />
            )}
          </>
        )}

        <Grid
          container
          spacing={!showTrialCTA || enableOffers ? 0 : 4}
          style={{
            marginTop: showTrialCTA ? -10 : 20,
          }}
        >
          <Grid
            item
            xs={12}
            md={!showTrialCTA || enableOffers ? 12 : 6}
            style={{
              backgroundColor: palette.light.secondary.lighter,
              borderRadius: 16,
              paddingRight: 20,
              paddingTop: !showTrialCTA || enableOffers ? 20 : 'auto',
              paddingBottom: 20,
            }}
          >
            <Typography
              style={{
                fontSize: 17,
                fontWeight: 'bold',
                textAlign: !showTrialCTA || enableOffers ? 'center' : 'left',
              }}
              marginBottom={3}
            >
              {strings('unlockPotential')}
            </Typography>
            <div
              style={{
                width: !showTrialCTA || enableOffers ? '350px' : 'auto',
                margin: !showTrialCTA || enableOffers ? '0 auto' : 'auto',
              }}
            >
              <List
                data={benefitsList}
                gridItemProps={{
                  xs: 12,
                }}
                renderItem={(benefit) => (
                  <BenefitListItem
                    description={benefit.description}
                    icon={benefit.icon}
                    alt={benefit?.alt}
                  />
                )}
              />
            </div>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{ display: 'block', margin: '20px auto', fontSize: 32 }}
            />

            <Typography
              paragraph
              fontSize={17}
              marginBottom={0}
              textAlign="center"
            >
              {strings('manyMoreFeatures')}
            </Typography>
          </Grid>
          {showTrialCTA && !enableOffers && (
            <Grid item xs={12} md={6}>
              <Typography
                style={{ fontSize: 17, fontWeight: 'bold' }}
                marginBottom={3}
              >
                {strings('startingIsEasy')}
              </Typography>
              <List
                data={howToStartList}
                renderItem={(benefit) => (
                  <BenefitListItem
                    description={benefit.description}
                    icon={benefit.icon}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </m.div>

      <div style={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          {onCancel && (
            <Button onClick={onCancel} style={{ marginTop: 20 }}>
              Cancelar
            </Button>
          )}
        </m.div>
      </div>

      <Dialog open={showConfirmation} onClose={handleContinue}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <Check
            style={{
              color: 'white',
              fontSize: 64,
              backgroundColor: '#48B223',
              padding: 20,
              borderRadius: 100,
              boxShadow: '0px 0px 0px 5px #C7EFB9',
              marginBottom: 20,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography>{strings('welcomeToCabalPlus')}</Typography>
        </DialogContent>
        <DialogActions style={{ paddingTop: 0 }}>
          <Button fullWidth onClick={handleContinue} variant="contained">
            {strings('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};
