import { Card, CardContent, Container, Typography } from '@mui/material';
import { m } from 'framer-motion';

import { ForbiddenIllustration } from '../assets';
import { MotionContainer, varBounce } from './animate';
import { useStrings } from 'src/hooks/useStrings';

export interface PermissionDeniedMessageProps {
  title?: string;
  message?: string;
  showIllustration?: boolean;
  useCard?: boolean;
}

export const PermissionDeniedMessage = ({
  title,
  message,
  showIllustration = true,
  useCard = false,
}: PermissionDeniedMessageProps) => {
  const { strings } = useStrings();
  
  title = title || strings('permissionDenied');
  message = message || strings('noAccess');

  const body = (
    <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          {title}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>{message}</Typography>
      </m.div>
      {showIllustration && (
        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      )}
    </Container>
  );

  return useCard ? (
    <Card>
      <CardContent>{body}</CardContent>
    </Card>
  ) : (
    body
  );
};
