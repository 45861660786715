import { CabalPermissionName, PermissionModule } from 'src/@types/Permission';
import { AuthUser } from 'src/@types/auth';
import { permissionNames } from 'src/sections/@dashboard/RolesPermissions/RoleManager/RoleForm/PermissionsList/permissionsCatalog';

import useAuth from './useAuth';

type RoleName =
  | 'owner'
  | 'guest'
  | 'guestWithoutOrganization'
  | 'Administrador'
  | 'Vendedor'
  | 'paid_customer';

const mapPermissions = (permissionName: CabalPermissionName, user: AuthUser) =>
  user?.current_permissions
    ? {
      [permissionName]: user?.current_permissions[permissionName],
    }
    : null;

const generateAllPermission = (
  permissionNames: CabalPermissionName[],
  permissions: PermissionModule
) =>
  permissionNames
    .map((name) => permissions[name])
    .reduce((prev, curr) => prev && curr);

const generatePermissions = (
  permissionNames: CabalPermissionName[],
  user: AuthUser
) => {
  const permissions = permissionNames
    .map((name) => mapPermissions(name, user))
    .reduce((prev, curr) => ({
      ...prev,
      ...curr,
    }));

  if (permissions) {
    if (user?.current_organization?.main_role === 'owner') {
      permissions.all = true;
    } else {
      permissions.all = generateAllPermission(
        permissionNames,
        permissions as unknown as PermissionModule
      );
    }
  }

  return permissions;
};

export const usePermissions = () => {
  const { user } = useAuth();
  const isGuestWithoutOrganization = Boolean(
    user?.current_organization === null && user?.user.is_guest
  );
  const isOwner = user?.current_organization?.main_role === 'owner';
  const currentRole = isOwner ? 'owner' : user?.current_permissions?.name;

  const isOrganizationOwner = (id: number) => {
    const organization = user?.organizations.find(
      (organization) => organization.id === id
    );

    if (organization) {
      return organization.main_role === 'owner';
    }

    return isOwner;
  };

  const isAccountType = (accountType: 'business' | 'person' | 'guest') => {
    if (accountType === 'guest' && user?.user.is_guest) return true;

    return user?.current_organization?.business_type === accountType;
  };

  const can = (permissionNames: CabalPermissionName[]) => {
    if (!user || permissionNames.length === 0) return false;
    if (isOwner || isAccountType('person')) return true;
    let result = false;

    result = permissionNames
      .map((name) => user?.current_permissions![name])
      .reduce((prev, curr) => prev && curr);

    return result;
  };

  const is = (roleName: RoleName) => {
    if (!user) return false;
    if (roleName === 'guestWithoutOrganization')
      return isGuestWithoutOrganization;
    if (roleName === 'paid_customer') return user.user.paid_customer;
    if (roleName === 'guest')
      return user.current_organization?.main_role === 'guest';
    if ((roleName === 'owner' && isOwner) || isAccountType('person'))
      return true;

    return roleName === user?.current_permissions!.name;
  };

  const has = (
    name: 'paid_access' | 'unified_transactions' | 'multiple_organizations'
  ) => {
    if (!user) return false;

    if (name === 'paid_access')
      return Boolean(user.current_organization?.has_paid_access);
    if (name === 'multiple_organizations')
      return user.organizations && user.organizations.length > 1;
    if (name === 'unified_transactions')
      return Boolean(user.current_organization.unified_transactions);

    return false;
  };

  const pos = generatePermissions(permissionNames.pos, user);
  const inventory = generatePermissions(permissionNames.inventory, user);
  const sales = generatePermissions(permissionNames.sales, user);
  const expenses = generatePermissions(permissionNames.expenses, user);
  const eeff = generatePermissions(permissionNames.eeff, user);
  const categories = generatePermissions(permissionNames.categories, user);
  const budgetAssistant = generatePermissions(
    permissionNames.budgetAssistant,
    user
  );
  const details = generatePermissions(permissionNames.details, user);
  const store = generatePermissions(permissionNames.store, user);

  return {
    has,
    can,
    currentRole,
    is,
    isOrganizationOwner,
    isOwner,
    isAccountType,
    pos,
    inventory,
    sales,
    expenses,
    eeff,
    categories,
    budgetAssistant,
    details,
    store,
  };
};
